import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import Divider from '@mui/material/Divider'
import { isEmpty } from 'lodash'

import {
  CircularProgress,
  TextField,
  MenuItem,
  Grid,
  InputAdornment,
} from '@mui/material'
import { Sort, Search, Add as AddIcon } from '@mui/icons-material'
import {
  fetchBoards,
  boardAddAssets,
  boardsSort,
  boardsFilter,
  createBoard,
} from '../../store/boards/actions'
import BoardsAssets from './BoardsAssets'
import { BOARD_SORT_OPTIONS } from '../../constants/boards'
import { selectDownloadAssets } from '../../store/search/selector'
import {
  selectIsAssetAdded,
  selectBoardDownloadAssets,
} from '../../store/boards/selector'
import { boardDashboardRouteRegex } from '../../../src/helpers/Regexes'
import {
  selectSearchSelectedItems,
  selectUserId,
} from '../../store/auth/selector'
import withRouter from '../../containers/Router/WithRouter'

const styles = makeStyles((theme) => ({
  actionIcons: {
    marginRight: '10px',
    color: useTheme().palette.primary.lightGrey,
  },
  actionIconsAlign: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  assetsContainer: {
    marginTop: '16px',
    height: '666px',
    overflowY: 'auto',
    minHeight: '100%',
    marginBottom: 50,
  },
  filtersSection: {
    margin: '10px 10px 10px 24px',
    //display: 'flex'
  },
}))

const createSortByOptions = () =>
  Object.keys(BOARD_SORT_OPTIONS).map((key) => (
    <MenuItem key={key} value={key}>
      {BOARD_SORT_OPTIONS[key]}
    </MenuItem>
  ))

export class BoardsDialog extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      sortByOption: 'last_updated',
      filterBy: '',
      selectedBoards: [],
      createNewBoard: false,
      newBoardName: '',
      currentLocation: null,
      isUploadSuccessfull: false,
      boardID: null,
    }
  }

  componentDidMount() {
    const { sortByOption = '' } = this.state
    this.props.fetchBoards(sortByOption)
  }

  componentDidUpdate(prevProps) {
    const { isAssetAdded } = this.props
    const { isAssetAdded: prevIsAssetAdded } = prevProps

    const { selectedBoards = [], currentLocation = '' } = this.state
    if (
      selectedBoards.length > 0 &&
      isAssetAdded &&
      isAssetAdded !== prevIsAssetAdded
    ) {
      this.setState({ selectedBoards: [] })
    }

    if (currentLocation !== window.location.pathname) {
      this.setState({ currentLocation: window.location.pathname })
    }
  }

  onSortByChange = (event) => {
    const sortType = event.target.value
    if (sortType) {
      this.setState({ sortByOption: sortType })
      this.props.boardsSort({ sortType })
    }
  }

  onFilterByChange = (event) => {
    const filterValue = event.target.value
    this.setState({ filterBy: filterValue })
    this.props.boardsFilter({ filterValue })
  }
  onAddToBoard = (name = '') => {
    const { selectedBoards = [] } = this.state
    this.setState(
      { selectedBoards: [...selectedBoards, name], boardID: name },
      () => {
        this.addAssetToBoard()
      }
    )
  }
  assetsToAdd = (downloadAssets = {}) => {
    const assetToAdd = Object.values(downloadAssets).map((asset) => {
      const newObj = {}
      if (asset) {
        const {
          assetId = '',
          fileName = '',
          filePath = '',
          importDate = '',
          originalUploadedDate = '',
          renderUrls = {}
        } = asset
        const {
          downloadUrl = ''
        } = renderUrls
        let modifiedOriginalDate = ''
        if (originalUploadedDate) {
          modifiedOriginalDate = new Date(Date.parse(originalUploadedDate))?.toISOString()
        }
        newObj['asset_id'] = assetId
        newObj['file_name'] = fileName
        newObj['asset_preview_path'] = filePath ? filePath.split('?')[0] : downloadUrl.split('?')[0]
        newObj['import_date'] = importDate ? importDate : modifiedOriginalDate
      }
      return newObj
    })
    return assetToAdd
  }
  addAssetToBoard = () => {
    const {
      downloadAssets = [],
      boardDownloadAssets = {},
      lanId = '',
      boardAddAssets = () => { },
      searchSelectedItems = [],
    } = this.props
    const {
      selectedBoards = [],
      currentLocation = '',
      sortByOption = '',
    } = this.state
    const boardsWithAssets = {}
    let assetsToAdd = {}

    if (currentLocation.match(boardDashboardRouteRegex)) {
      assetsToAdd = this.assetsToAdd(boardDownloadAssets)
    } else {
      const currentAssets = !Object.entries(downloadAssets).length
        ? searchSelectedItems
        : downloadAssets
      assetsToAdd = this.assetsToAdd(currentAssets)
    }

    selectedBoards.map((obj) => {
      boardsWithAssets[obj] = Object.keys(assetsToAdd).map(
        (assetId) => assetsToAdd[assetId]
      )
      return obj
    })
    boardAddAssets(
      { ...boardsWithAssets },
      lanId,
      'search',
      sortByOption,
      () => {
        this.setState({ isUploadSuccessfull: true }, () => {
          setTimeout(() => {
            this.setState({ isUploadSuccessfull: false, boardID: null })
          }, 2000)
        })
      }
    )
  }

  createBoard = () => {
    const { downloadAssets = {}, lanId = '' } = this.props
    const { newBoardName = '' } = this.state
    const assets = Object.values(downloadAssets).map(
      ({ assetId = '', fileName = '', filePath = '' }) => ({
        asset_id: assetId,
        file_name: fileName,
        asset_preview_path: filePath.split('?')[0],
      })
    )
    this.props.createBoard(newBoardName, assets, lanId)
    this.setState({ createNewBoard: false, newBoardName: '' })
  }

  sortedListBasedOnUpdateDate = (list) =>
    list.sort(function (item1, item2) {
      return new Date(item2.updated_date) - new Date(item1.updated_date)
    })

  render() {
    const {
      boards = {},
      classes = {},
      history = {},
      router = {},
      downloadAssets = {},
      boardDownloadAssets = {},
      searchSelectedItems = [],
    } = this.props
    const {
      selectedBoards = [],
      filterBy = '',
      isUploadSuccessfull = false,
      boardID = null,
      currentLocation = null,
    } = this.state
    const { isFetching, boardList = [] } = boards
    const generatedList = boardList.filter(
      (board) => board.show === undefined || board.show === true
    )
    const sortedGeneratedList = this.sortedListBasedOnUpdateDate(generatedList)

    return (
      <div>
        <TextField
          placeholder="Board name"
          onChange={this.onFilterByChange}
          value={filterBy}
          variant="outlined"
          size={'small'}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          style={{
            width: '90%',
            margin: '16px 10px 0px 18px',
          }}
        />
        {isFetching && (
          <Grid container justifyContent={'center'}>
            <CircularProgress className={classes.assetsContainer} />
          </Grid>
        )}
        {!isFetching && (
          <div className={classes.assetsContainer}>
            {sortedGeneratedList.map((board, idx) => (
              <div>
                <Divider />
                <BoardsAssets
                  board={board}
                  boardID={boardID}
                  key={idx}
                  onAddToBoard={this.onAddToBoard}
                  selectedBoards={selectedBoards}
                  isUploadSuccessfull={isUploadSuccessfull}
                  isAssetSelected={
                    currentLocation !== null
                      ? currentLocation.includes('search') ||
                        currentLocation.includes('betaSearch')
                        ? !isEmpty(downloadAssets) ||
                        !isEmpty(searchSelectedItems)
                        : currentLocation.includes('boards')
                          ? !isEmpty(boardDownloadAssets)
                          : false
                      : false
                  }
                  history={history}
                  router={router}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}

BoardsDialog.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  classes: PropTypes.object,
  fetchBoards: PropTypes.func.isRequired,
  boardAddAssets: PropTypes.func.isRequired,
  boardsSort: PropTypes.func.isRequired,
  boardsFilter: PropTypes.func.isRequired,
  boards: PropTypes.object,
  downloadAssets: PropTypes.object,
  boardDownloadAssets: PropTypes.object,
  isAssetAdded: PropTypes.bool,
  selectedItems: PropTypes.array,
  theme: PropTypes.object,
  createBoard: PropTypes.func,
  lanId: PropTypes.string,
}

const mapStateToProps = (state) => ({
  boards: state.boards,
  boardDownloadAssets: selectBoardDownloadAssets()(state),
  downloadAssets: selectDownloadAssets()(state),
  isAssetAdded: selectIsAssetAdded()(state),
  lanId: selectUserId()(state),
  searchSelectedItems: selectSearchSelectedItems()(state),
})

const mapDisatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchBoards,
      boardAddAssets,
      boardsSort,
      boardsFilter,
      createBoard,
    },
    dispatch
  )

const MyComponent = (props) => {
  const classes = styles()
  return <BoardsDialog {...props} classes={classes} />
}

export default connect(
  mapStateToProps,
  mapDisatchToProps
)(withRouter(MyComponent))
