import React, { useState } from 'react'
import { Tabs, Tab } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PromptSettings from '../pages/PromptSettings'
import { tabsConfig } from '../config/SettingsConfig'

const useStyles = makeStyles({
  settingsTabStyles: {
    background: '#F5F5F5'
  }
})


const Settings = () => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState('prompts');

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <Tabs value={selectedTab} onChange={handleTabChange} centered className={classes.settingsTabStyles}>
        {tabsConfig.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      {selectedTab === 'prompts' && <PromptSettings data-testid='prompt-settings'/>}
    </div>
  )
};

export default Settings;
