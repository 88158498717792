export const promptCategoryList = [
  'Classification',
  'Description',
  'Keywords',
  'Focus Items',
  'Style/Decor',
  'Alt Text',
  'Seasonal',
  'Portrait Compatible',
  'Infographics',
  'Custom',
]

export const DEFAULT_GENAI_MODEL = 'gpt-4o'

export const availableAiModels = [DEFAULT_GENAI_MODEL]

export const CUSTOM_TIMESTAMP = 'DD MMMM YYYY - h:mm A'

export const ASSET_STATUS_SUCCESS = 'SUCCESS'

export const ASSET_STATUS_FAILURE = 'FAILURE'

export const bulkReviewAssetTableCells = [
  {
    id: 'image',
    label: 'Image',
    disablePadding: true,
  },
  {
    id: 'number',
    disablePadding: true,
    label: 'No.',
  },
  {
    id: 'response',
    label: 'Response',
    width: '400px',
  },
  {
    id: 'quality',
    label: 'Quality',
  },
  {
    id: 'Notes',
    label: 'Notes',
  },
]

export const bulkTestReviewFullDetails = [
  {
    label: 'Test ID',
    value: 'test_id',
    show: true,
  },
  {
    label: 'Test Name',
    value: 'test_name',
    show: true,
  },
  {
    label: 'Model Used',
    value: 'model_used',
    show: true,
  },
  {
    label: '# Images',
    value: 'number_of_images',
    show: true,
  },
  {
    label: 'Reviews Completed',
    value: 'review_complete_percentage',
    type: 'percentage',
    show: true,
  },
  {
    label: 'Passed',
    value: 'pass_percentage',
    type: 'percentage',
    show: true,
  },
  {
    label: 'Created By',
    value: 'test_creator',
    show: true,
  },
  {
    label: 'Created Date',
    value: 'create_timestamp',
    type: 'timestamp',
    show: true,
  },
  {
    label: 'Boards',
    value: 'boards',
    type: 'boards',
    show: true,
  },
  {
    label: 'Test Notes',
    value: 'test_notes',
    type: 'text',
    editable: true,
    show: true,
  },
  {
    label: '# Unprocessed Images',
    value: 'pending_assets_count',
    type: 'retry',
    show: false,
  },
]

export const bulkTestReviewInitDetails = [
  {
    label: 'Test Details',
    value: '',
    show: true,
  },
  {
    label: '# Images',
    value: 'number_of_images',
    show: true,
  },
  {
    label: 'Reviews Completed',
    value: 'review_complete_percentage',
    type: 'percentage',
    show: true,
  },
  {
    label: 'Passed',
    value: 'pass_percentage',
    type: 'percentage',
    show: true,
  },
  {
    label: '# Unprocessed Images',
    value: 'pending_assets_count',
    type: 'retry',
    show: false,
  },
]
