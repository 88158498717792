import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { omit, isEqual, sortBy } from 'lodash'
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomButtonGroup from '../../../components/CustomButtonGroup';
import { aiClassificationFilterList, FILTER_AI_CLASSIFICATION, FILTER_AI_TALENT } from '../../../constants/search';
import { BetaAnalytics } from '../../../../analytics/betaSearch';
import { useSearchContext } from '../SearchContext';
import { replaceFilters } from '../../../store/search/searchSlice';
import { handleMultiCheckboxChangeEvent } from '../../../utils/search';

const useStyles = makeStyles({
  aiClassificationContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px',
  },
  talentGrid: {
    padding: '15px 15px 0px 15px'
  },
  listItem: {
    padding: '15px',
  },
  checkbox: {
    '&.Mui-disabled': {
      color: '#00000061 !important',
    },
  },
})

const AIClassificationFilter = ({ closePopOver = () => { }, availableFilters = {} }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const appliedClassificationFilters = availableFilters?.[FILTER_AI_CLASSIFICATION] || []
  const appliedTalentValue = availableFilters?.[FILTER_AI_TALENT]?.[0] || ''
  const [withTalent, setWithTalent] = useState(appliedTalentValue)
  const [selectedClassifications, setSelectedClassifications] = useState(appliedClassificationFilters)
  const { trackAnalyticsData = () => { } } = useSearchContext()

  const isApplyDisabled = isEqual(sortBy(selectedClassifications), sortBy(appliedClassificationFilters)) && withTalent === appliedTalentValue

  const handleChange = (classificationValue = '') => {
    const updatedClassificationList = handleMultiCheckboxChangeEvent(selectedClassifications, classificationValue)
    setSelectedClassifications(updatedClassificationList)
  };

  const handleAIClassificationTrackingEvent = (analyticsFilterData = {}) => {
    trackAnalyticsData({
      eventType: BetaAnalytics.event.ADD_FILTER,
      eventName: BetaAnalytics.event.FILTER_AI_CLASSIFICATION,
      eventData: analyticsFilterData,
    })
  }

  const onClickApply = () => {
    let aiClassificationFilters = { ...availableFilters }
    if (selectedClassifications.length) {
      aiClassificationFilters[FILTER_AI_CLASSIFICATION] = selectedClassifications
    } else if (FILTER_AI_CLASSIFICATION in aiClassificationFilters) {
      aiClassificationFilters = omit(aiClassificationFilters, [
        FILTER_AI_CLASSIFICATION,
      ])
    }
    if (withTalent !== '') {
      aiClassificationFilters[FILTER_AI_TALENT] = [withTalent]
    } else if (FILTER_AI_TALENT in aiClassificationFilters) {
      aiClassificationFilters = omit(aiClassificationFilters, [
        FILTER_AI_TALENT,
      ])
    }
    dispatch(replaceFilters({ filters: aiClassificationFilters }))
    closePopOver()
    const analyticsFilterData = {
      classifications: selectedClassifications,
      isTalentFilter: withTalent
    }
    handleAIClassificationTrackingEvent(analyticsFilterData)
  };

  const onClickClear = () => {
    setSelectedClassifications([])
    setWithTalent('')
  }

  return (
    <div>
      <Grid container className={classes.aiClassificationContainer}>
        <Grid item className={classes.talentGrid}>
          <FormControl>
            <FormLabel
              id="people-row-radio-buttons-group-label"
            >
              Talent
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="people-radio-buttons-group-label"
              name="people-radio-buttons-group"
              value={withTalent}
              onChange={(event) => setWithTalent(event.target.value)}
            >
              <FormControlLabel
                value="true"
                control={<Radio className={classes.radioButton} />}
                label="With Talent"
              />
              <FormControlLabel
                value="false"
                control={<Radio className={classes.radioButton} />}
                label="Without Talent"
              />
              <FormControlLabel
                value=""
                control={<Radio className={classes.radioButton} />}
                label="No Preference"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item className={classes.listItem}>
          <FormGroup>
            <FormLabel
              id="people-row-radio-buttons-group-label"
            >
              Product
            </FormLabel>
            {aiClassificationFilterList.map((item, index) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedClassifications.indexOf(item.key) !== -1}
                    onChange={() => handleChange(item.key)}
                    name={item.key}
                    key={index}
                  />
                }
                label={item.label}
              />
            ))}
          </FormGroup>
        </Grid>
        <Grid item>
          <CustomButtonGroup
            onClickClear={onClickClear}
            onClickApply={onClickApply}
            isApplyDisabled={isApplyDisabled}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AIClassificationFilter;