import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { debounce, find, each, isEmpty } from 'lodash'

import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  CircularProgress,
  Grid,
  Slide,
} from '@mui/material'
import { People, Clear, Title, PersonAdd } from '@mui/icons-material'

import UserListTable from './UserListTable'
import { createGroup } from './../../store/groups/actionCreator'
import { getTmList } from './../../store/teamMemberList/actionCreator'
import AutocompleteChip from '../AutocompleteChip/AutocompleteChip'
import { fieldValidRegex } from '../../helpers/Regexes'
import { TEXT_SPECIAL_CHARS_FORM_VALID } from '../../constants/notifications'
import { showNotification } from '../../store/notification/actionCreator'
import { selectUserId } from '../../store/auth/selector'

import { ADMIN_ROLE_ID, DEFAULT_ROLE_ID } from '../../constants/userRoles'

const styles = makeStyles((theme) => ({
  margin: {
    margin: useTheme().spacing(),
  },
  textField: {
    flexBasis: 200,
  },
  emptyGroup: {
    border: '1px dashed #c5c5c5',
    marginTop: 20 + ' px',
    textAlign: 'center',
    padding: '15% 0',
  },
  leftIcon: {
    marginRight: useTheme().spacing(),
  },
  groupListWrapper: {
    minHeight: 300,
    marginTop: 10,
  },
  wrapper: {
    margin: useTheme().spacing(),
    position: 'relative',
  },
  button: {
    backgroundColor: '#0D46A0',
    '&:hover': {
      backgroundColor: '#0D46A0',
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  AutoCompleteWarpper: {
    backgroundColor: '#f7f7f7',
    border: '1px solid #EFEFEF',
    paddingBottom: 8,
  },
}))

function Transition(props) {
  return <Slide direction="left" {...props} />
}

export class AddGroupForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      group: {
        group_name: '',
        group_users: [],
        is_public: true,
      },
      userList: [],
    }
    this.callAjax = debounce(this.callAjax, 500)
  }

  callAjax = (value) => {
    this.props.getTmList(value)
  }

  handleChange = (prop) => (event) => {
    const { group = {} } = this.state
    this.setState({ group: { ...group, [prop]: event.target.value } })
  }

  userChange = (name, data, status = '') => {
    if (name === 'delete') return
    let invalidEmailList = []
    let validUserList = []
    let { userList: stateUserList = [] } = this.state
    const { group = {} } = this.state
    const { group_users: groupList = [] } = group
    const { login_id = '', role_id = DEFAULT_ROLE_ID } = data
    let person = Array.isArray(data) ? data : [data]
    const personExists = person.length > 0
    const isUserExists = find(
      stateUserList,
      (participant = {}) =>
        personExists &&
        person.some((newPerson = {}) =>
          newPerson.group_id && newPerson.group_id !== null
            ? participant.group_id === newPerson.group_id
            : participant.login_id === newPerson.login_id
        )
    )
    if (!isUserExists) {
      if (personExists) {
        each(person, function (newPerson) {
          let personDetail = { ...newPerson }
          const {
            email_address: np_email_address = '',
            display_name: np_display_name = null,
          } = personDetail
          if (
            !np_display_name &&
            !!invalidEmailList.indexOf(np_email_address)
          ) {
            invalidEmailList.push(np_email_address)
          } else {
            validUserList.push(personDetail)
          }
        })
        groupList.push({ login_id, role_id })
        if (!isEmpty(validUserList)) {
          stateUserList.push(...validUserList)
        }
        this.setState({
          group: {
            ...group,
            group_users: groupList,
          },
          userList: [...stateUserList],
        })
      }
    } else {
      let msg = !status ? person[0].display_name : ' Few participants'
      if (!status || status === 'show') {
        this.props.showNotification(
          true,
          msg + ' has already been added to this list.',
          'success'
        )
      }
    }
  }
  userNameAfterPaste = (name = '', obj = []) => {
    let count = 0
    obj.map((value) => {
      if (!value.error) {
        count++
        let last = obj.length === count ? 'show' : 'hide'
        this.userChange(name, value, last)
      }
    })
  }
  userNameChange = (name) => {
    name && this.callAjax(name)
  }

  removeUser = (data) => {
    const { login_id = '' } = data
    const { group = {} } = this.state
    let { userList = [] } = this.state
    const { group_users = [] } = group
    let groupList = group_users.filter((user) => user.login_id !== login_id)
    let indexOfUserList = userList.indexOf(data)
    if (indexOfUserList !== -1) {
      userList.splice(indexOfUserList, 1)
    }
    this.setState({
      group: {
        ...group,
        group_users: groupList,
      },
      userList: userList,
    })
    this.setState({ chipData: userList })
  }

  peopleList = () => {
    let list = ''
    const { group = {}, userList = [] } = this.state
    const { group_users = [] } = group
    const { classes = {} } = this.props
    if (group_users.length) {
      list = (
        <UserListTable
          userList={userList}
          onDelete={this.removeUser}
          onUserRoleChange={this.onUserRoleChange}
        />
      )
    } else {
      list = (
        <div className={classes.emptyGroup}>
          <div>
            <People style={{ fontSize: 77 }} />
          </div>
          Nobody is added to this group yet!
        </div>
      )
    }
    return list
  }

  saveGroup = () => {
    const { group = {} } = this.state
    this.props.createGroup(group)
  }
  onUserRoleChange = (userData) => {
    const { login_id, role_id = DEFAULT_ROLE_ID } = userData
    const { userList = [], group = {} } = this.state
    const { group_users = [] } = group

    let changedRole =
      role_id === ADMIN_ROLE_ID ? DEFAULT_ROLE_ID : ADMIN_ROLE_ID
    let newUserList = userList.map((user) => {
      let newUser = { ...user }
      if (newUser.login_id === login_id) {
        newUser.role_id = changedRole
      }
      return newUser
    })
    let updated_group_users = group_users.map((user) => {
      let newUser = { ...user }
      if (newUser.login_id === login_id) {
        newUser.role_id = changedRole
      }
      return newUser
    })
    this.setState({
      group: {
        ...group,
        group_users: updated_group_users,
      },
      userList: [...newUserList],
    })
  }

  render() {
    const { group: stateGroup = {} } = this.state
    const { group_name = '', group_users = [] } = stateGroup
    const {
      classes = {},
      open = false,
      tmList = [],
      groups: propsGroups = {},
    } = this.props
    const { loading = {} } = propsGroups
    const { createGroupLoading = false } = loading
    const grpNameError = (group_name.match(fieldValidRegex) || []).length > 0
    return (
      <Dialog
        open={open}
        onClose={this.props.onClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        TransitionComponent={Transition}
        fullWidth
      >
        <AppBar className="dialogTitleBar">
          <Toolbar className="dialogTitleHeight">
            <Typography color="inherit" className="dialogTitle">
              Create Group
            </Typography>
            <IconButton
              className="dialogClose"
              aria-label="Delete"
              onClick={this.props.onClose}
              size="large"
            >
              <Clear />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <form>
            {/* <div style={{color: '#949494', fontSize: 15, padding: '8px 0 0 0'}}> */}
            {/* Name your group. */}
            {/* </div> */}
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <Title style={{ fontSize: 30, marginBottom: 8 }} />
              </Grid>
              <Grid item sm>
                <TextField
                  variant="standard"
                  id="group_name"
                  value={group_name}
                  onChange={this.handleChange('group_name')}
                  label="Group Name"
                  error={grpNameError}
                  fullWidth
                  margin="normal"
                  helperText={
                    "You'll search for this name to use your group." +
                    ' ' +
                    (grpNameError ? TEXT_SPECIAL_CHARS_FORM_VALID : '')
                  }
                  inputProps={{ 'data-cy': 'addGroupNameText' }}
                />
              </Grid>
            </Grid>
            {/* <div style={{color: '#949494', fontSize: 15, padding: '8px 0 0 0'}}> */}
            {/* Build your group. */}
            {/* </div> */}
            <Grid
              className={classes.AutoCompleteWarpper}
              container
              spacing={1}
              alignItems="flex-end"
            >
              <Grid item>
                <PersonAdd style={{ fontSize: 30, marginBottom: 8 }} />
              </Grid>
              <Grid item sm style={{ marginRight: 12, position: 'relative' }}>
                {/* specifiy onChipPasteCallBack={(<PARAM>) => this.<yourMethodName>(<PARAM>)} for any callback or to customize the events */}
                <AutocompleteChip
                  tmList={tmList}
                  onNameChange={(name) => this.userNameChange(name)}
                  hideClose
                  fieldName="group_users"
                  Label="Add Individual"
                  type="search"
                  values={[]}
                  onChipPasteCallBack={(arr) =>
                    this.userNameAfterPaste('add_group_list', arr)
                  }
                  isChipRequired={false}
                  onChange={(name, value) => this.userChange(name, value)}
                />
              </Grid>
            </Grid>
          </form>
          <div className={classes.groupListWrapper}>{this.peopleList()}</div>
        </DialogContent>
        <DialogActions>
          <div className={classes.wrapper}>
            <Button
              data-cy="addGroupCreateButton"
              variant="contained"
              className={classes.button}
              onClick={this.saveGroup}
              color="primary"
              disabled={
                !group_users.length || !group_name || createGroupLoading
              }
            >
              <People className={classes.leftIcon} />
              Create Group
            </Button>
            {createGroupLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

AddGroupForm.propTypes = {
  classes: PropTypes.object.isRequired,
  lanId: PropTypes.string,
  getTmList: PropTypes.func.isRequired,
  createGroup: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  tmList: PropTypes.array,
  groups: PropTypes.shape({
    loading: PropTypes.shape({
      createGroupLoading: PropTypes.bool,
    }),
  }),
  showNotification: PropTypes.func,
}

const mapStateToProps = (state = {}) => {
  const lanId = selectUserId()(state)
  const { tmList, groups } = state
  return {
    lanId,
    tmList,
    groups,
  }
}

const MyComponent = (props) => {
  const classes = styles()
  return <AddGroupForm {...props} classes={classes} />
}

export default connect(mapStateToProps, {
  getTmList,
  createGroup,
  showNotification,
})(MyComponent)
