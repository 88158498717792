import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { debounce, find } from 'lodash'
import { withStyles } from '@mui/styles'
/**Material Components */
import {
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
} from '@mui/material'
/**Material Icons */
import { People, Clear, PersonAdd } from '@mui/icons-material'
/**Internal Components */
import AutocompleteChip from '../../../components/AutocompleteChip/AutocompleteChip'
import UserListTable from '../../../components/Groups/UserListTable'
/**Action Creators*/
import { addUsersToBoard } from '../../../store/boards/actions'
import { getTmList } from '../../../store/teamMemberList/actionCreator'
import { showNotification } from '../../../store/notification/actionCreator'
/**Selectors */
import { selectUserId } from '../../../store/auth/selector'
/**Constants */
import { ADMIN_ROLE_ID, DEFAULT_ROLE_ID } from '../../../constants/userRoles'

const styles = (theme) => ({
  emptyGroup: {
    border: '1px dashed #c5c5c5',
    marginTop: 20 + ' px',
    textAlign: 'center',
    padding: '15% 0',
  },
  groupListWrapper: {
    minHeight: 300,
    marginTop: 10,
  },
  dialogTitle: {
    color: '#ffffff',
  },
  AutoCompleteWarpper: {
    backgroundColor: '#f7f7f7',
    border: '1px solid #EFEFEF',
    paddingBottom: 8,
  },
})

export class EditBoardUserForm extends React.Component {
  constructor(props) {
    super(props)
    const { lanId } = this.props
    this.state = {
      userList: [],
      login_id: lanId,
    }
    this.callAjax = debounce(this.callAjax, 500)
  }

  componentDidMount() {
    const { formData = {}, lanId = '' } = this.props
    if (Object.keys(formData)?.length) {
      this.setState({
        login_id: lanId,
        userList: [...formData],
      })
    }
    this.initialUserList = [...formData]
  }

  componentDidUpdate(prevProps) {
    const { formData: prevformData } = prevProps
    const { formData } = this.props
    if (prevformData.length !== formData.length) {
      this.setState({ userList: formData })
    } else {
      const isAnyDifference = this.checkDifference(formData, prevformData)
      if (isAnyDifference) {
        this.setState({ userList: formData })
      }
    }
  }

  checkDifference = (updatedList, preList) => {
    let isAnyDifference = false
    updatedList.forEach((element, index) => {
      if (preList[index].role_id !== element.role_id) {
        isAnyDifference = true
      }
    })
    return isAnyDifference
  }

  callAjax = (value) => {
    this.props.getTmList(value)
  }

  userNameAfterPaste(name, obj) {
    let length = Object.keys(obj).length
    let count = 0
    obj.map((value) => {
      if (!value.error) {
        count++
        let last = length === count ? 'show' : 'hide'
        this.userChange(name, value, last)
      }
      return value
    })
  }

  userChange = (name, data, status = '') => {
    if (name === 'delete') return
    let { userList: stateUserList = [] } = this.state
    const {
      login_id = '',
      role_id = DEFAULT_ROLE_ID,
      group_id = '',
      list_id = '',
    } = data
    let person = Array.isArray(data) ? data : [data]
    const personExists = person.length > 0
    const isUserExists = find(
      stateUserList,
      (participant = {}) =>
        personExists &&
        person.some((newPerson = {}) =>
          newPerson.group_id && newPerson.group_id !== null
            ? participant.group_id === newPerson.group_id
            : newPerson.login_id && newPerson.login_id !== null
            ? participant.login_id === newPerson.login_id
            : participant.list_id === newPerson.list_id
        )
    )
    if (!isUserExists) {
      if (personExists) {
        let user = {}
        if (login_id !== '') {
          user = {
            login_id: login_id,
            role_id: role_id,
          }
        } else if (group_id !== '') {
          user = {
            list_id: group_id,
            role_id: role_id,
          }
        } else {
          user = {
            list_id: list_id,
            role_id: role_id,
          }
        }
        this.addMembersToBoard([user])
      }
    } else {
      let msg = status ? ' Few participants' : person[0].display_name
      if (!status || status === 'show') {
        this.props.showNotification(
          true,
          msg + ' has already been added to this board.',
          'success'
        )
      }
    }
  }

  userNameChange = (name) => {
    name && this.callAjax(name)
  }

  removeUser = (data = {}) => {
    this.props.removeSpecicificUserFromBoard(data)
  }

  peopleList = () => {
    let list = ''
    const { userList = [] } = this.state
    const { classes = {} } = this.props
    if (userList.length) {
      list = (
        <UserListTable
          userList={userList}
          onDelete={this.removeUser}
          onUserRoleChange={this.onUserRoleChange}
        />
      )
    } else {
      list = (
        <div className={classes.emptyGroup}>
          <div>
            <People style={{ fontSize: 77 }} />
          </div>
          Nobody is added to this board yet!
        </div>
      )
    }
    return list
  }

  addMembersToBoard = (user = []) => {
    const {
      boardId = '',
      handleRefreshBoardMember = () => {},
      lanId = '',
    } = this.props
    const payload = {
      board_id: boardId,
      board_users: user,
    }
    this.props.addUsersToBoard(payload, lanId, () => {
      handleRefreshBoardMember()
    })
  }

  onUserRoleChange = (userData = {}) => {
    const {
      board_user_id: userId = '',
      role_id: roleId = DEFAULT_ROLE_ID,
      login_id: loginId = '',
      list_id: listId = '',
    } = userData
    const id = roleId === DEFAULT_ROLE_ID ? ADMIN_ROLE_ID : DEFAULT_ROLE_ID
    this.props.updateAccessBoardMember(userId, id, loginId, listId)
  }

  render() {
    const {
      classes = {},
      open = false,
      tmList = {},
      boardName = '',
    } = this.props
    return (
      <Dialog
        open={open}
        onClose={this.props.onClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        fullWidth
      >
        <AppBar className="dialogTitleBar">
          <Toolbar className="dialogTitleHeight">
            <Typography color="inherit" className="dialogTitle">
              Edit Board: {boardName}
            </Typography>
            <IconButton
              className="dialogClose"
              aria-label="Delete"
              onClick={this.props.onClose}
              size="large"
            >
              <Clear />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <form>
            <Grid
              className={classes.AutoCompleteWarpper}
              container
              spacing={1}
              alignItems="flex-end"
            >
              <Grid item>
                <PersonAdd style={{ fontSize: 30, marginBottom: 8 }} />
              </Grid>
              <Grid item sm style={{ marginRight: 12, position: 'relative' }}>
                <AutocompleteChip
                  tmList={tmList}
                  onNameChange={(name) => this.userNameChange(name)}
                  hideClose
                  fieldName="group_users"
                  Label="Add Individual or Group"
                  type="search"
                  values={[]}
                  isChipRequired={false}
                  onChange={(name, value) => this.userChange(name, value)}
                  onChipPasteCallBack={(arr) =>
                    this.userNameAfterPaste('edit_board_list', arr)
                  }
                />
              </Grid>
            </Grid>
          </form>
          <div className={classes.groupListWrapper}>{this.peopleList()}</div>
        </DialogContent>
      </Dialog>
    )
  }
}

EditBoardUserForm.propTypes = {
  classes: PropTypes.object.isRequired,
  lanId: PropTypes.string,
  formData: PropTypes.object,
  getTmList: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  tmList: PropTypes.object,
  showNotification: PropTypes.func,
  addUsersToBoard: PropTypes.func.isRequired,
  boardName: PropTypes.string,
  boardId: PropTypes.string,
  handleRefreshBoardMember: PropTypes.func,
  removeSpecicificUserFromBoard: PropTypes.func,
  updateAccessBoardMember: PropTypes.func,
}

const mapStateToProps = (state = {}) => {
  const lanId = selectUserId()(state)
  const { tmList = {} } = state
  return {
    lanId,
    tmList,
  }
}
export default connect(mapStateToProps, {
  getTmList,
  addUsersToBoard,
  showNotification,
})(withStyles(styles)(EditBoardUserForm))
