import axios from 'axios'
import apiConfig from '../../config/apiConfig'

const {
  genai: {
    promptsUrl = ''
  } = {},
} = apiConfig

export const fetchPromptData = () => axios.get(promptsUrl)

export const fetchAllVersionsPromptData = (category = '') => axios.get(`${promptsUrl}/${category}/versions`)

export const createNewPrompt = (requestPayload = {}) => axios.post(promptsUrl, requestPayload)

export const updatePromptByPromptId = (requestPayload = {}) => axios.put(`${promptsUrl}`, requestPayload)

