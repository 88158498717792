export const promptConfig = [
  { label: 'Category', property: 'category' },
  { label: 'Version', property: 'version' },
  { label: 'Name', property: 'name' },
  { label: 'Context', property: 'context' },
  { label: 'Prompt', property: 'prompt' },
  { label: 'Last Modified By', property: 'updated_by' },
  { label: 'Last Modified Date', property: 'created_timestamp', type: 'timestamp' },
  { label: 'Last Run Date', property: 'last_run_timestamp', type: 'timestamp' },
];

export const runPromptCategories = [
  { label: 'Keywords', value: 'KEYWORDS' },
  { label: 'Description', value: 'DESCRIPTION' },
  { label: 'Classification', value: 'CLASSIFICATION' },
  { label: 'Talent', value: 'TALENT' },
]