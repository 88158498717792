export const METADATA_CARD_SHOW_BUTTON_TEXT = 'SHOW DETAILS'
export const METADATA_CARD_HIDE_BUTTON_TEXT = 'HIDE DETAILS'

export const METADATA_LOOKUP_ERROR =
  'AssetHub is experiencing a problem. Please try again later.'
export const METADATA_LOOKUP_STORE_ERROR = 'Store # is not valid'
export const METADATA_LOOKUP_PRODUCT_ERROR = 'DPCI or TCIN is not valid'
export const METADATA_LOOKUP_DPCI_ERROR = 'DPCI is not valid'
export const METADATA_LOOKUP_TCIN_ERROR = 'TCIN is not valid'
export const METADATA_LOOKUP_DRM_ERROR = 'DRM ID is not valid'

export const METADATA_CARD_PRODUCT_INFO_TITLE = 'Product Information'
export const METADATA_CARD_STORE_INFO_TITLE = 'Store Information'
export const METADATA_CARD_ASSET_DESC_TITLE = 'Asset Descriptions'
export const METADATA_CARD_ASSET_CLASSIFICATION = 'Asset Classifications'
export const METADATA_CARD_PROJECT_TITLE = 'Project'
export const METADATA_CARD_USER_TAGS = 'Tags'
export const METADATA_CARD_AI_KEYWORDS = 'AI Keywords'
export const METADATA_CARD_AI_CLASSIFICATION = 'AI Classification'
export const METADATA_CARD_AI_DESCRIPTION = 'AI Description'

export const METADATA_CATALOG_TYPES = [
  'subject',
  'location',
  'shottype',
  'assetClassification',
]

export const METADATA_REVISION_DELETE_TEXT =
  'All review comments will be permanently removed from AssetHub.'
export const METADATA_REVISION_DELETE_TITLE = 'Delete Revision'

export const NOTIFICATION_SUCCESS_REVISION_DELETE =
  '1 Revision Deleted <br /> To undo this action contact the AssetHub team <br /> via the "Need Help?" button'

export const NOTIFICATION_SUCCESS_ASSET_DELETE =
  '{assetCount} Asssets Deleted <br /> To undo this action contact the AssetHub team <br /> via the "Need Help?" button'

export const ASSET_HUB_FILE_SOURCE_TYPE = 'enrichment-app'

export const ASSET_BASIC_REVIEW_COMPLETED = 'Basic Review Completed'
export const ASSET_ADVANCE_REVIEW_COMPLETED = 'Advanced Review Complete'
export const ASSET_REVIEW_FLAGGED = 'Flagged'
export const ASSET_REVIEW_PENDING = 'Review Pending'
export const TAB_KEY_CODE = 9
export const ENTER_KEY_CODE = 13
export const ALLOWED_ACTION_KEYS = [ENTER_KEY_CODE, TAB_KEY_CODE]
export const DISPLAY_BRAND_CLASSIFICATION_TYPES = [
  'Design File',
  'Non-Product Image',
]
