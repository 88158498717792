import axios from 'axios'
import Moment from 'moment'

import { omit, pickBy, has } from 'lodash'
import {
  CLEAR_SEARCH_RESULTS,
  CONTENT_SEARCH_API_CALL,
  DELETE_ASSETS_INPROGRESS,
  DOWNLOAD_ASSETS_INPROGRESS,
  FETCH_SEARCH_TEMPLATES,
  UPDATE_FACET_FILTER,
  UPDATE_SEARCH_TERM,
  FETCH_TYPEAHEAD_SUGGESTIONS,
  CLEAR_TYPEAHEAD_SUGGESTIONS,
  SET_SORT_FIELDS,
  UPDATE_DOWNLOAD_ASSETS,
  SET_TEMPLATE_ID,
  BOARDS_CONTENT_SEARCH_API_CALL,
  FETCH_METADATA_FOR_ADMIN_INITIATED,
  FETCH_METADATA_FOR_ADMIN_FULFILLED,
  FETCH_METADATA_FOR_ADMIN_REJECTED,
  FETCH_AC_SUGGESTIONS_INITIATED,
  SET_CURRENT_PAGE_SIZE,
  SET_SEARCH_IMAGE_PREVIEW,
} from './actionTypes'
import apiConfig from '../../config/apiConfig'
import {
  selectAppliedFilters,
  selectSearchTemplates,
  selectSearchTerm,
} from './selector'
import { getModifiedFilters } from './helpers'
import {
  SEARCH_TEMPLATE_DEFAULT_VALUE,
  FACET_UPLOAD_DATE_NAME,
  FACET_UPLOAD_DATE_VALUE,
  FACET_CLASSIFICATION_MISSING_KEY_VALUE,
} from '../../constants/search'
import firefly from '../../analytics/firefly'

const defaultPayload = {
  filters: {},
}
const KEY = apiConfig.key
export const fetchAssets =
  ({
    index = '',
    filters = {},
    isNewSearch,
    pageNumber = 0,
    searchTerm = '',
    size = 21,
    templateId = '',
    strict = true,
    sort_fields = {},
    userId = '',
  }) =>
  (dispatch, getState) => {
    const state = getState()
    const payload = { ...defaultPayload, filters, sort_fields }
    payload.strict = strict
    payload.search_term = searchTerm
    if (templateId && templateId !== SEARCH_TEMPLATE_DEFAULT_VALUE) {
      payload.template_id = templateId
    }
    const searchBaseURL = `${apiConfig.search.contentSearchUrl}?key=${KEY}&page=${pageNumber}&per_page=${size}`
    const promise = axios.post(searchBaseURL, payload)

    return dispatch({
      type: CONTENT_SEARCH_API_CALL,
      payload: promise,
    }).then(({ value = {} }) => {
      const { data = {} } = value
      const { search = [] } = data
      if (isNewSearch) {
        selectSearchTemplates()(state)
      } else {
        // data.filters || {}
      }
      if (search.length > 0) {
        const asset = search[0]
        firefly.trackSearchHighlights(asset.highlights, userId)
      }
    })
  }

export const fetchReverseImageAssets =
  ({
    index = '',
    filters = {},
    pageNumber = 0,
    size = 21,
    sort_fields = {},
    userId = '',
    file = {},
  }) =>
  (dispatch, getState) => {
    const state = getState()
    const payload = { sort_fields: { ...sort_fields } }
    const searchBaseURL = `${apiConfig.search.contentReverseImageSearchUrl}`
    const formData = new FormData()
    formData.append(
      'assetSearchFiltersRequest',
      new window.Blob([JSON.stringify(payload)], {
        type: 'application/json',
      })
    )
    formData.append('file', file)
    const promise = axios.post(searchBaseURL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return dispatch({
      type: CONTENT_SEARCH_API_CALL,
      payload: promise,
    }).then(({ value = {} }) => {
      selectSearchTemplates()(state)
      const { data = {} } = value
      const { search = [] } = data
      if (search.length > 0) {
        const asset = search[0]
        firefly.trackSearchHighlights(asset.highlights, userId)
      }
    })
  }

export const fetchBoardAssetsMetadata =
  ({
    filters = {},
    pageNumber = 0,
    searchTerm = '',
    size = 21,
    strict = true,
    sort_fields = {},
  }) =>
  (dispatch) => {
    const payload = { ...defaultPayload, filters, sort_fields }
    payload.strict = strict
    payload.search_term = searchTerm

    const searchBaseURL = `${apiConfig.metadata.assetMetadataUrl}${filters.assetId}`
    const promise = axios.post(searchBaseURL, payload)

    return dispatch({
      type: BOARDS_CONTENT_SEARCH_API_CALL,
      payload: promise,
    })
  }

export const fetchMetadatadetails =
  (selected_file_ids = [], callBack = () => {}) =>
  (dispatch) => {
    let payloadFilters = {}

    payloadFilters['asset_id'] = selected_file_ids
    const payload = { filters: payloadFilters }
    const {
      filters: { asset_id = [] },
    } = payload
    const assetMetadataUrlForAdmin = `${apiConfig.metadata.assetMetadataUrlForAdmin}`
    dispatch(fetchMetadatadetailsInnitiated())
    return axios
      .post(
        assetMetadataUrlForAdmin,
        {
          asset_ids: asset_id || [],
        },
        {
          params: {
            key: KEY,
          },
        }
      )
      .then((response) => {
        const { data: responseData = '' } = response
        if (responseData) {
          downloadCSV(responseData)
          dispatch(fetchMetadatadetailsComplated())
          callBack()
        }
      })
      .catch((e) => {
        dispatch(fetchMetadatadetailsError(e))
      })
  }
const downloadCSV = (response) => {
  const file = new Blob([response], {
    type: 'data:text/csv;charset=utf-8',
  })
  const fileURL = window.URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = fileURL
  link.setAttribute(
    'download',
    `TTR-${Moment().format('MMDDYYYY-THHmmss')}.csv`
  )
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const updateSearchTerm = (searchTerm) => ({
  type: UPDATE_SEARCH_TERM,
  payload: { searchTerm },
})

export const saveAppliedFilters = (appliedFilters) => ({
  type: UPDATE_FACET_FILTER,
  payload: { appliedFilters },
})

export const clearSearchAssets = (retainPartialState = false) => ({
  type: CLEAR_SEARCH_RESULTS,
  payload: { retainPartialState: retainPartialState },
})

export const updateFacetFilter =
  ({
    index,
    actionType,
    filterName,
    filterValue,
    searchTerm,
    size,
    sort_fields = {},
    templateId,
  }) =>
  (dispatch, getState) => {
    const state = getState()
    let appliedFilters = selectAppliedFilters()(state)
    if (filterName === FACET_UPLOAD_DATE_NAME) {
      appliedFilters = omit(appliedFilters, FACET_UPLOAD_DATE_VALUE)
    }
    const modifiedFilters = getModifiedFilters({
      actionType,
      appliedFilters,
      filterName,
      filterValue,
    })
    if (
      actionType === 'REMOVE' &&
      !has(modifiedFilters, FACET_CLASSIFICATION_MISSING_KEY_VALUE)
    ) {
      templateId = ''
    }
    /**Filtering keys with null values from modifiedFilters object */
    const newModifiedFilters = pickBy(modifiedFilters)
    dispatch(saveAppliedFilters(newModifiedFilters))
    dispatch(
      fetchAssets({
        filters: newModifiedFilters,
        searchTerm,
        size,
        sort_fields,
        templateId,
        index,
      })
    )
  }

export const fetchNextPageSearchResults =
  (pageNumber) => (dispatch, getState) => {
    const state = getState()
    const searchTerm = selectSearchTerm()(state)
    const filters = selectAppliedFilters()(state)
    dispatch(fetchAssets({ filters, pageNumber, searchTerm }))
  }

export const fetchSearchTemplates = () => (dispatch) => {
  const promise = axios.get(apiConfig.search.searchAdminTemplateUrl)
  return dispatch({
    type: FETCH_SEARCH_TEMPLATES,
    payload: promise,
  })
}

export const fetchTypeaheadSuggestions = (searchTerm) => (dispatch) => {
  const promise = axios.get(
    `${apiConfig.search.typeaheadSuggestionsUrl}?searchTerm=${encodeURIComponent(searchTerm)}`
  )
  return dispatch({
    type: FETCH_TYPEAHEAD_SUGGESTIONS,
    payload: promise,
  })
}

export const resetBetaSearchSelectedItems = (selectedItems = []) => ({
  type: 'search/saveSelectedItems',
  payload: { selectedItems: [] },
})

export const clearTypeaheadSuggestions = () => ({
  type: CLEAR_TYPEAHEAD_SUGGESTIONS,
})

export const setSortFields = (sortFields) => ({
  type: SET_SORT_FIELDS,
  payload: { sortFields },
})
export const setCurrentPageSize = (pageSize = 21) => ({
  type: SET_CURRENT_PAGE_SIZE,
  payload: { pageSize },
})

export const updateDownloadAssets = (downloadAssets = {}) => ({
  type: UPDATE_DOWNLOAD_ASSETS,
  payload: { downloadAssets },
})

export const setDownloadAssetInProgress = (downloadInProgress) => ({
  type: DOWNLOAD_ASSETS_INPROGRESS,
  payload: { downloadInProgress },
})

export const setTemplateId = (templateId = SEARCH_TEMPLATE_DEFAULT_VALUE) => ({
  type: SET_TEMPLATE_ID,
  payload: { templateId },
})

export const setDeleteAssetInProgress = (deleteAssetInProgress) => ({
  type: DELETE_ASSETS_INPROGRESS,
  payload: { deleteAssetInProgress },
})

export const fetchMetadatadetailsInnitiated = () => ({
  type: FETCH_METADATA_FOR_ADMIN_INITIATED,
  payload: { isMetadataDownloadInitiated: true },
})

export const fetchMetadatadetailsComplated = () => ({
  type: FETCH_METADATA_FOR_ADMIN_FULFILLED,
  payload: { isMetadataDownloadInitiated: false },
})

export const fetchMetadatadetailsError = () => ({
  type: FETCH_METADATA_FOR_ADMIN_REJECTED,
  payload: { isMetadataDownloadInitiated: false },
})

export const setPreviewImage = (url) => ({
  type: SET_SEARCH_IMAGE_PREVIEW,
  payload: { url },
})

export const fetchACSuggestions =
  ({ searchQuery = '', facetName = '' }) =>
  (dispatch) => {
    const url = `${apiConfig.search.typeaheadSuggestionsUrl}?searchTerm=${encodeURIComponent(searchQuery)}`
    dispatch(fetchACSuggestionsInitiated())
    return axios
      .get(url)
      .then((response) => response.data.autocomplete_suggestions[facetName])
  }

export const fetchACSuggestionsInitiated = () => ({
  type: FETCH_AC_SUGGESTIONS_INITIATED,
  payload: { isFetchingACSuggestions: true },
})
