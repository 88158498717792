import React, { useState, useEffect, startTransition } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles'
import ClearIcon from '@mui/icons-material/Clear'
import { Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { DEFAULT_GENAI_MODEL, availableAiModels } from '../../genai/constants/genai';
import { runPromptCategories } from '../config/PromptConfig';
import { convertDateToDisplay } from '../../helpers/dateHelper';
import { createNewPrompt, updatePromptByPromptId } from '../services/promptService';

const useStyles = makeStyles({
  promptHeading: {
    padding: '0px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DDDDDD'
  },
  clearIconStyles: {
    cursor: 'pointer'
  },
  promptFieldsClass: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '30px',
  },
  buttonGroup: {
    display: 'flex',
    gap: '20px',
    padding: '0px 30px',
  },
  historyStyles: {
    margin: '20px',
    border: '1px solid #DDDDDD',
    width: 'calc(100% - 40px)',
  },
  historyRowClass: {
    maxHeight: '40px'
  },
  versionHeaderClass: {
    padding: '0px 10px',
  },
  versionClass: {
    padding: '0px 10px',
    color: '#178295',
    cursor: 'pointer'
  },
  additionalItemStylesWithBorder: {
    borderRight: '1px solid #DDDDDD',
    padding: '5px 10px'
  },
  additionalItemStyles: {
    padding: '5px 10px'
  },
  infoLabel: {
    color: '#666666',
    fontSize: '14px',
    paddingBottom: '5px',
  },
  historyLabel: {
    padding: '5px',
    borderBottom: '1px solid #DDDDDD'
  },
  tableBodyClasses: {
    height: '300px',
    overflowY: 'scroll',
  }
});

const DrawerPromptDetails = ({
  promptDetails = [],
  parentClasses = {},
  mode = 'add',
  clearAndRefresh = () => { },
  onClickClear = () => { },
  setDrawerMode = () => { }
}) => {
  const classes = useStyles()
  const { displayName = '' } = useSelector(state => state?.auth || {})
  const drawerTitle = mode === 'add' ? 'Add Prompt' : 'View Prompt'
  const {
    name: respName = '',
    model_used = DEFAULT_GENAI_MODEL,
    category: respCategory = 'KEYWORDS',
    context: respContext = '',
    prompt: respPrompt = '',
  } = promptDetails[0] || {}
  const [name, setName] = useState(respName)
  const [model, setModel] = useState(model_used)
  const [category, setCategory] = useState(respCategory)
  const [context, setContext] = useState(respContext)
  const [prompt, setPrompt] = useState(respPrompt)
  const [selectedDataIndex, setSelectedDataIndex] = useState(0)

  useEffect(() => {
    const {
      name = '',
      model_used = '',
      category = '',
      context = '',
      prompt = '',
    } = promptDetails[selectedDataIndex] || {}

    startTransition(() => {
      setName(name)
      setModel(model_used)
      setCategory(category)
      setContext(context)
      setPrompt(prompt)
    })
  }, [selectedDataIndex])


  const onSave = () => {
    // Save the prompt based on the mode
    let promptPayload = {
      name,
      model_used: model,
      category,
      context,
      prompt,
    }
    if (mode === 'add') {
      promptPayload['created_by'] = displayName
      promptPayload['updated_by'] = displayName
      createNewPrompt(promptPayload).then((response) => {
        clearAndRefresh()
      })
    } else {
      promptPayload['updated_by'] = displayName
      updatePromptByPromptId(promptPayload).then((response) => {
        clearAndRefresh()
      })
    }
  }

  const onClickCancel = () => {
    onClickClear()
  }

  const editPromptDetails = () => {
    setDrawerMode('edit')
  }

  return (
    <>
      <div className={classes.promptHeading}>
        <h3>{drawerTitle}</h3>
        <ClearIcon onClick={onClickClear} className={classes.clearIconStyles} />
      </div>
      {(
        <Grid container className={classes.promptFieldsClass}>
          <Grid item>
            <FormControl size="small">
              <InputLabel id="category-label">Category</InputLabel>
              <Select
                style={{ width: '200px', height: '50px' }}
                labelId="category-label"
                id="category-label"
                value={category}
                input={<OutlinedInput label="Category" />}
                onChange={(event) => setCategory(event.target.value)}
                disabled={mode !== 'add'}
              >
                {runPromptCategories.map((category) => (
                  <MenuItem value={category.value}>{category.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl size="small">
              <InputLabel id="model-label">Model Used</InputLabel>
              <Select
                style={{ width: '200px', height: '50px' }}
                labelId="model-label"
                id="model-label"
                value={model}
                input={<OutlinedInput label="Model Used" />}
                onChange={(event) => setModel(event.target.value)}
                disabled={mode === 'view'}
              >
                {availableAiModels.map((category) => (
                  <MenuItem value={category}>{category}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              required
              id='name'
              label='Name'
              data-testid='name-input'
              placeholder='Enter Name'
              InputLabelProps={{ shrink: true }}
              value={name}
              fullWidth
              onChange={(event) => setName(event.target.value)}
              disabled={mode === 'view'}
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id='context-name'
              label='Context'
              placeholder='Enter Context'
              InputLabelProps={{ shrink: true }}
              value={context}
              fullWidth
              multiline
              minRows={4}
              onChange={(event) => setContext(event.target.value)}
              disabled={mode === 'view'}
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id="prompt-name"
              label="Prompt"
              placeholder="Enter Prompt"
              InputLabelProps={{ shrink: true }}
              value={prompt}
              fullWidth
              multiline
              minRows={6}
              onChange={(event) => setPrompt(event.target.value)}
              disabled={mode === 'view'}
            />
          </Grid>
        </Grid>
      )}
      {
        selectedDataIndex === 0 && (mode === 'view' ? (
          <Grid container className={classes.buttonGroup}>
            <Button
              name='edit'
              variant="outlined"
              className={parentClasses.buttonStyle}
              onClick={editPromptDetails}
            >
              <EditIcon fontSize='small' />
              Edit
            </Button>
          </Grid>
        ) : (

          <Grid container className={classes.buttonGroup}>
            <Button
              variant="outlined"
              className={parentClasses.buttonStyle}
              onClick={onSave}
              disabled={
                !(
                  prompt &&
                  category &&
                  model &&
                  context &&
                  name
                )
              }
            >
              Save
            </Button>
            <Button
              variant="outlined"
              className={classes.transparentButton}
              onClick={onClickCancel}
            >
              Cancel
            </Button>
          </Grid>
        )
        )}
      {
        mode !== 'add' && (
          <Grid container className={classes.historyStyles}>
            <Grid item container className={classes.historyLabel}>
              History
            </Grid>
            <Grid item container>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 250 }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right" className={classes.versionHeaderClass}>Version</TableCell>
                      <TableCell align="right">Last Modified By</TableCell>
                      <TableCell align="right">Last Modified Date</TableCell>
                      <TableCell align="right">Last Run Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBodyClasses}
                  >
                    {promptDetails.map((versionData, versionIndex) => {
                      const {
                        version = '',
                        created_by = '',
                        created_timestamp = '',
                        last_run_timestamp = ''
                      } = versionData
                      return (
                        <TableRow
                          key={version}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          selected={selectedDataIndex === versionIndex}
                          className={classes.historyRowClass}
                        >
                          <TableCell onClick={() => setSelectedDataIndex(versionIndex)} className={classes.versionClass}>
                            {version}
                          </TableCell>
                          <TableCell align="right">{<div>{created_by}</div>
                          }</TableCell>
                          <TableCell align="right">{convertDateToDisplay(created_timestamp)}</TableCell>
                          <TableCell align="right">{last_run_timestamp && convertDateToDisplay(last_run_timestamp)}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )
      }
    </>
  );
};

export default DrawerPromptDetails;
