import ProjectsPage from '../ProjectsPage'
import Search from '../Search'
import NewSearch from '../NewSearch'
import { AHSearch } from '../MicroFrontends'
import GroupsPage from '../Groups'
import Boards from '../../containers/Boards'
import UsageRights from '../UsageRights/UsageRights'
import HelpPage from '../../containers/Help'
import HomePage from '../HomePage/HomePage'
import HomePageContainer from '../../containers/Home'
import Publish from '../Publish/index'
import NotFound from '../NotFound'
import NestedProjectViews from '../ProjectsPage/project/NestedProjectViews'
import GroupDashboardPage from '../Groups/Group/dashboard'
import BoardDashboard from '../../containers/Boards/Board/BoardDashboard'
import Annotation from '../../containers/Annotation'
import UsageRightsCreate from '../UsageRights/UsageRightsCreate'
import PublishUpload from '../Publish/Upload/PublishUpload'
import ChannelPublish from '../Publish/ChannelUpload/ChannelPublish'
import AssetClassification from '../Classification/AssetClassification'
import { fetchPageEntitlements } from '../../helpers/UtilityHelper'
import Unauthorized from '../Unauthorized'
import PromptPlayground from '../../genai/pages/PromptPlayground'
import BulkTestsListView from '../../genai/pages/BulkTestsListView'
import BulkTestReviewPage from '../../genai/pages/BulkTestReviewPage'
import NewBulkTestView from '../../genai/pages/NewBulkTestView'
import {
  SINGLE_PLAYGROUND_VIEW_AI,
  BULK_TESTS_VIEW_AI,
} from '../../constants/routes'
import Metadata from '../../betasearch/pages/Metadata'
import Settings from '../../settings/pages/Settings'

const navRoutes = [
  {
    path: '/',
    exact: true,
    routeName: 'Home',
    component: HomePageContainer,
    tooltip: 'Home',
    displayName: 'Home',
    adGroups: (env) => fetchPageEntitlements(env, 'assethubADGroups'),
  },
  {
    path: '/search/:assetRevId/',
    routeName: 'search',
    displayName: 'Search',
    component: Search,
    adGroups: (env) => fetchPageEntitlements(env, 'search'),
    exact: true,
  },
  {
    path: '/search/:assetRevId/:assetOriginalId',
    routeName: 'search',
    displayName: 'Search',
    component: Search,
    adGroups: (env) => fetchPageEntitlements(env, 'search'),
    exact: true,
  },
  {
    path: '/search',
    routeName: 'search',
    displayName: 'Search',
    component: Search,
    adGroups: (env) => fetchPageEntitlements(env, 'search'),
    exact: true,
  },
  {
    path: '/betaSearch',
    routeName: 'betaSearch',
    displayName: 'Beta Search',
    component: NewSearch,
    adGroups: (env) => fetchPageEntitlements(env, 'betaSearch'),
    exact: true,
  },
  {
    path: '/betaSearch/:assetId/metadata',
    routeName: 'Metadata',
    component: Metadata,
    tooltip: 'Metadata',
    displayName: 'Metadata',
    adGroups: (env = {}) => fetchPageEntitlements(env, 'betaSearch'),
  },
  {
    path: '/groups',
    routeName: 'groups',
    displayName: 'Groups',
    component: GroupsPage,
    adGroups: (env) => fetchPageEntitlements(env, 'groups'),
    exact: true,
  },
  {
    path: '/groups/:groupId/dashboard',
    routeName: 'groups',
    displayName: 'Groups',
    component: GroupDashboardPage,
    adGroups: (env) => fetchPageEntitlements(env, 'groups'),
    exact: true,
  },
  {
    path: '/boards',
    routeName: 'boards',
    displayName: 'Boards',
    component: Boards,
    adGroups: (env) => fetchPageEntitlements(env, 'boards'),
    exact: true,
  },
  {
    path: '/boards/:boardId/dashboard',
    routeName: 'boards',
    displayName: 'Boards',
    component: BoardDashboard,
    adGroups: (env) => fetchPageEntitlements(env, 'boards'),
    exact: true,
  },
  {
    path: '/project/:projectId/:pageName/*',
    routeName: 'projects',
    tooltip: 'Projects',
    displayName: 'Projects',
    component: NestedProjectViews,
    adGroups: (env) => fetchPageEntitlements(env, 'projects'),
  },
  {
    path: '/projects',
    routeName: 'projects',
    tooltip: 'Projects',
    displayName: 'Projects',
    component: ProjectsPage,
    adGroups: (env) => fetchPageEntitlements(env, 'projects'),
    exact: true,
  },
  {
    path: '/annotator/:projectId/:jobId',
    routeName: 'annotation',
    tooltip: 'Annotation',
    displayName: 'Annotation',
    component: Annotation,
    adGroups: (env) => fetchPageEntitlements(env, 'projects'),
  },
  {
    path: '/annotator/:projectId/:jobId/assetUniqueId/:assetUniqueId/:revisionNumber/:pageNumber',
    routeName: 'annotation',
    tooltip: 'Annotation',
    displayName: 'Annotation',
    component: Annotation,
    adGroups: (env) => fetchPageEntitlements(env, 'projects'),
  },
  {
    path: '/usageRights',
    routeName: 'usageRights',
    component: UsageRights,
    adGroups: (env) => fetchPageEntitlements(env, 'usageRights'),
    exact: true,
  },
  {
    path: '/usageRights/create',
    routeName: 'usageRightsCreate',
    component: UsageRightsCreate,
    adGroups: (env) => fetchPageEntitlements(env, 'usageRights'),
    exact: true,
  },
  {
    path: '/usageRights/details/:drmId',
    routeName: 'usageRightsEdit',
    component: UsageRightsCreate,
    adGroups: (env) => fetchPageEntitlements(env, 'usageRights'),
    exact: true,
  },
  {
    path: '/publishing',
    routeName: 'publish',
    component: Publish,
    adGroups: (env) => fetchPageEntitlements(env, 'publish'),
    exact: true,
  },
  {
    path: '/publishing/:asset_id',
    routeName: 'publishAsset',
    component: Publish,
    adGroups: (env) => fetchPageEntitlements(env, 'publish'),
    exact: true,
  },
  {
    path: '/publishing/channelPublish',
    routeName: 'publishAsset',
    component: ChannelPublish,
    adGroups: undefined,
    exact: true,
  },
  {
    path: '/publishing/publishUpload',
    routeName: 'publishAsset',
    component: PublishUpload,
    adGroups: undefined,
    exact: true,
  },
  {
    path: '/classification',
    routeName: 'Classification',
    component: AssetClassification,
    adGroups: (env) => fetchPageEntitlements(env, 'classification'),
    exact: true,
  },
  {
    path: '/settings',
    routeName: 'Settings',
    component: Settings,
    adGroups: (env) => fetchPageEntitlements(env, 'settings'),
    exact: true,
  },
  {
    path: '/help',
    routeName: 'help',
    component: HelpPage,
    adGroups: (env) => fetchPageEntitlements(env, 'help'),
    exact: true,
  },
  {
    path: SINGLE_PLAYGROUND_VIEW_AI,
    routeName: 'ai_imagetotext',
    component: PromptPlayground,
    adGroups: (env) => fetchPageEntitlements(env, 'ai_imagetotext'),
    exact: true,
  },
  {
    path: BULK_TESTS_VIEW_AI,
    routeName: 'bulktests',
    component: BulkTestsListView,
    adGroups: (env) => fetchPageEntitlements(env, 'ai_imagetotext_bulk'),
    exact: true,
  },
  {
    path: '/ai_imagetotext/bulktests/new',
    routeName: 'bulktesting_newtest',
    component: NewBulkTestView,
    adGroups: (env) => fetchPageEntitlements(env, 'ai_imagetotext_bulk'),
    exact: true,
  },
  {
    path: '/ai_imagetotext/bulktests/:bulktestid',
    routeName: 'bulktesting_newtest',
    component: BulkTestReviewPage,
    adGroups: (env) => fetchPageEntitlements(env, 'ai_imagetotext_bulk'),
    exact: true,
  },
  {
    path: '/unauthorized',
    name: 'UnAuthorized',
    tooltip: 'UnAuthorized',
    displayName: '',
    component: Unauthorized,
    exact: true,
    adGroups: undefined,
  },
  {
    path: '*',
    name: 'Missed Routes',
    component: NotFound,
    adGroups: (env) => fetchPageEntitlements(env, 'home'),
  },
]

export default navRoutes
