import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useEnv } from '@praxis/component-runtime-env'
import {
  createTheme,
  styled,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { Badge } from '@mui/material'
import { sideNavConfig } from '../../config/sideNavConfig'
import { Icon } from '../SideNav'
import { isAuthorizedToPage } from '../../helpers/UtilityHelper'
import { closeNoAccessPopUp } from '../../store/auth/actions'
import { closeSideNav } from '../../store/layout/actionCreator'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -8,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '16px',
          backgroundColor: '#3A3952',
        },
        tooltipPlacementRight: {
          right: '4px',
        },
        arrow: {
          color: '#3A3952',
        },
      },
    },
  },
})

function AHSideNav({ classes = {}, tab = '' }) {
  const dispatch = useDispatch()
  const envConfig = useEnv() || {}
  const {
    layout = {},
    auth = {},
    headerReducer: { role = '' } = {},
  } = useSelector((state) => state)
  const { sideNavIsOpen = false } = layout
  const [currentTab, setCurrentTab] = useState('home')

  const handleClick = (data = '') => {
    dispatch(closeNoAccessPopUp())
    if (sideNavIsOpen) {
      dispatch(closeSideNav())
    }
    setCurrentTab(data)
  }

  useEffect(() => {
    setCurrentTab(tab)
  }, [tab])

  const isAuthorizedPageHandler = (path = '') => {
    const { memberOf = [] } = auth
    const { REACT_APP_ROLES_CONFIG = {} } = envConfig
    const member = role ? [role] : memberOf
    return isAuthorizedToPage(path, member, REACT_APP_ROLES_CONFIG)
  }

  return (
    <div style={{ display: 'grid' }}>
      {sideNavConfig.map((navItem, index) => {
        const {
          roleName = '',
          label = '',
          redirectionUrl = '',
          renderCustomImage = false,
          renderCustomText = false,
          iconName = '',
          activeClass = 'activeLink',
          menuItemClass = 'menuItem',
        } = navItem
        return (
          isAuthorizedPageHandler(roleName) && (
            <StyledEngineProvider injectFirst key={index}>
              <ThemeProvider theme={theme}>
                <Tooltip arrow title={label} placement="right">
                  <Link
                    to={redirectionUrl}
                    data-cy={`${label}Link`}
                    onClick={() => handleClick(roleName)}
                    className={
                      currentTab === roleName
                        ? classes[activeClass]
                        : classes[menuItemClass]
                    }
                  >
                    <StyledBadge
                      badgeContent={roleName === 'betaSearch' ? 'BETA' : 0}
                      color="secondary"
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <Icon className={classes.sideNavIcon}>
                        {renderCustomImage ? (
                          <img
                            src={iconName}
                            alt={iconName}
                            className={
                              currentTab === roleName
                                ? classes.activeSideNavImg
                                : classes.sideNavImg
                            }
                          />
                        ) : renderCustomText ? (
                          <div className={classes.sideNavText}>{iconName}</div>
                        ): (
                          <navItem.iconName
                            className={
                              currentTab === roleName
                                ? classes.activeSideNavImg
                                : classes.sideNavImg
                            }
                          />
                        )}
                      </Icon>
                    </StyledBadge>
                  </Link>
                </Tooltip>
              </ThemeProvider>
            </StyledEngineProvider>
          )
        )
      })}
    </div>
  )
}

export default AHSideNav
