import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { each, find, isEmpty, remove } from 'lodash'
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  useTheme,
} from '@mui/material/styles'
import { withStyles, makeStyles } from '@mui/styles'
import Fab from '@mui/material/Fab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import CircularProgress from '@mui/material/CircularProgress'
import GroupIcon from '@mui/icons-material/Group'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import FilterList from '@mui/icons-material/FilterList'
import FaceIcon from '@mui/icons-material/Face'
import Paper from '@mui/material/Paper'
import DeleteIcon from '@mui/icons-material/DeleteSweep'

import ReviewerSvg from '../../images/Reviewer.svg?url'
import UploaderIcon from '../../images/upload.svg?url'
import { UserRoleIcon } from './components/UserRoleIcon'
import AutocompleteChip from '../AutocompleteChip/AutocompleteChip'
import {
  getTmList,
  fetchTeamMemberList,
} from './../../store/teamMemberList/actionCreator'
import { showNotification } from '../../store/notification/actionCreator'
import imageFallback from '../../images/ic_account_circle.png'
import { getDisplayName, isInternalUser } from '../../mappers/userMapper'

const themeRow = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 0,
          borderTop: '1px solid rgba(224, 224, 224, 1)',
        },
      },
    },
  },
})

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: useTheme().spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 1020,
  },
  fab: {
    position: 'absolute',
    backgroundColor: '#FC3',
    color: '#FFF',
    boxShadow: 'none',
  },
  participantIcon: {
    marginRight: 6,
    height: 56,
    width: 56,
    borderRadius: 40,
    position: 'relative',
    display: 'inline-block',
  },
  rowImage: {
    minWidth: 60,
    height: 65,
  },
  userEmail: {
    marginTop: 20,
  },
  userDisplayName: {
    position: 'absolute',
    marginTop: 20,
  },
  groupUsers: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
  groupRow: {
    backgroundColor: '#F4F4F4',
  },
  avatar: {
    backgroundColor: '#EFEFEF',
    height: 56,
    width: 56,
    marginRight: 5,
  },
  row: {
    display: 'flex',
    marginLeft: -50,
    position: 'relative',
  },
  actionRow: {
    width: 20,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  formControl: {
    margin: useTheme().spacing(),
    minWidth: 320,
    width: '40%',
  },
  cardHeader: {
    backgroundColor: 'rgba(239, 239, 239, 0.5)',
    minHeight: 75,
    maxHeight: 275,
  },
  FormIcon: {
    top: -10,
    position: 'relative',
    fontSize: 35,
    color: '#8b8b8b',
  },
  countUser: {
    color: '#8b8b8b',
    marginTop: 20,
    marginLeft: 20,
  },
  FormSVG: {
    width: 30,
    top: -15,
    position: 'relative',
    color: '#8b8b8b',
  },
  AutoComplete: {
    color: '#8b8b8b',
    marginTop: 0,
    minWidth: '60%',
  },
  userIcon: {
    color: '#757575',
    top: 8,
    position: 'relative',
  },
  rightSection: {
    paddingLeft: 20,
  },
  leftSection: {
    borderRight: '1px solid #8d8d8d',
    height: '100%',
    paddingRight: 20,
    paddingLeft: 20,
  },
  linkIcon: {
    color: '#0D46A0',
  },
  roleEl: {
    gap: '5px',
  },
  userEmailWithOrg: {
    paddingTop: '10px',
  },
}))

const filterBarStyles = makeStyles((theme) => ({
  root: {
    paddingRight: useTheme().spacing(),
  },
  FilterIcon: {
    top: -10,
    position: 'relative',
    fontSize: 20,
    color: '#8b8b8b',
  },
  formControl: {
    margin: useTheme().spacing(),
    minWidth: 320,
    width: '40%',
  },
}))

let EnhancedTableFilterbar = (props) => {
  const { value, handleSearch, classes } = props
  return (
    <Grid container alignItems="flex-end">
      <Grid item>
        <FilterList className={classes.FilterIcon} />
      </Grid>
      <Grid item sm style={{ marginLeft: 10 }}>
        <TextField
          variant="standard"
          id="searchFilterAssets"
          label="Filter by Name"
          onChange={handleSearch}
          margin="normal"
          type="search"
          value={value}
          className={classes.formControl}
        />
      </Grid>
    </Grid>
  )
}

EnhancedTableFilterbar.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSearch: PropTypes.func,
  value: PropTypes.string,
}

const EnhancedTableFilterbarMok = (props) => {
  const classes = filterBarStyles()
  return <EnhancedTableFilterbar {...props} classes={classes} />
}

// EnhancedTableFilterbar = EnhancedTableFilterbarMok

export class TaskPeopleList extends React.Component {
  constructor(props) {
    super(props)
    let participants = []
    let users = []
    if (Object.keys(this.props.info.task.default.participants).length > 0) {
      if (Object.keys(this.props.info.task.current.participants).length > 0) {
        users = this.props.info.task.current.participants
      } else {
        users = this.props.info.task.default.participants
      }
    }
    if (users) {
      each(users, function (user) {
        let participant = { ...user }
        if (!participant.addedTime) {
          participant.addedTime = window.Date.now()
        }
        participants.push(participant)
      })
    }
    let myData = participants
    this.state = {
      orderBy: 'addedTime',
      order: 'desc',
      data: myData,
      seleactSearchValue: [],
      filterData: myData,
      page: 0,
      rowsPerPage: 20,
      snackMessage: '',
    }
    this.handleSearch = this.handleSearch.bind(this)
    this.userNameChange = this.userNameChange.bind(this)
    this.handler = this.handler.bind(this)
  }

  handler = (event, person) => {
    let taskParticipants = this.state.data
    remove(taskParticipants, (participant = {}) => {
      const { group_id = '', role = '', user_id = '' } = participant
      return group_id && group_id !== null
        ? group_id === person.group_id
        : user_id === person.user_id && role === person.role
    })
    this.setState(
      { filterData: taskParticipants, data: taskParticipants },
      () => {
        this.props.handleStepper(this.state.filterData, 'participants')
      }
    )
  }
  callAjax(value, taskType) {
    this.props.getTmList(value, taskType)
  }
  userNameChange(name, taskType) {
    name && this.callAjax(name, taskType)
  }

  userNameAfterPaste(name, obj) {
    let length = Object.keys(obj).length
    let cnt = 0
    obj.map((value) => {
      if (!value.error) {
        cnt++
        let last = length === cnt ? 'show' : 'hide'
        this.userChange(name, value, last)
      }
    })
  }
  displayNotification = (displayName = '', status, trailingMsg = '') => {
    let msg = status === undefined ? displayName : ' Few participants'
    if (status === undefined || status === 'show') {
      this.props.showNotification(true, msg + trailingMsg, 'success')
    }
  }
  userChange(name, value, status) {
    let invalidEmailList = []
    let newObserverList = []
    let newReviewerList = []
    let newOuploaderList = []
    let taskParticipants = this.state.data
    let person = Array.isArray(value) ? value : [value]
    const _this = this
    if (
      !find(taskParticipants, function (participant) {
        return (
          person.length > 0 &&
          person.some((newPerson = {}) => {
            const { group_id = '', login_id = '' } = newPerson
            return group_id && group_id !== null
              ? participant.group_id === group_id &&
                  participant.role === 'admin'
              : participant.login_id === login_id &&
                  participant.role === 'admin'
          })
        )
      })
    ) {
      if (person.length > 0) {
        each(person, function (newPerson) {
          let personDetail = { ...newPerson }
          let fIndex = taskParticipants.findIndex((item) =>
            personDetail.group_id && personDetail.group_id !== null
              ? item.group_id === personDetail.group_id &&
                `task_${item.role.toLowerCase()}s` === name
              : item.login_id === personDetail.login_id &&
                `task_${item.role.toLowerCase()}s` === name
          )
          if (fIndex === -1) {
            let participantIndex = taskParticipants.findIndex((item) =>
              personDetail.group_id && personDetail.group_id !== null
                ? item.group_id === personDetail.group_id
                : item.login_id === personDetail.login_id
            )
            if (participantIndex !== -1) {
              taskParticipants.splice(participantIndex, 1)
            }
            if (
              personDetail.email_address !== null &&
              personDetail.display_name === undefined
            ) {
              // validate and populate
              if (
                personDetail.email_address &&
                !~invalidEmailList.indexOf(personDetail.email_address)
              ) {
                invalidEmailList.push(personDetail.email_address)
              }
              // check for the role and populate
            } else if (name === 'task_reviewers') {
              personDetail.addedTime = window.Date.now()
              personDetail.role = 'Reviewer'
              newReviewerList.push(personDetail)
            } else if (name === 'task_observers') {
              personDetail.addedTime = window.Date.now()
              personDetail.role = 'Observer'
              newReviewerList.push(personDetail)
            } else if (name === 'task_uploaders') {
              personDetail.addedTime = window.Date.now()
              personDetail.role = 'Uploader'
              newOuploaderList.push(personDetail)
            }
          } else {
            _this.displayNotification(
              personDetail.display_name,
              status,
              ' has already been added to this list'
            )
          }
        })

        if (
          !isEmpty(newReviewerList) ||
          !isEmpty(newObserverList) ||
          !isEmpty(newOuploaderList)
        ) {
          let newUsersList = Object.assign(
            newReviewerList,
            newObserverList,
            newOuploaderList
          )
          let userObj = Object.assign.apply(null, newUsersList) // Convert to object
          taskParticipants.push(userObj)
        }
        this.setState(
          { filterData: taskParticipants, data: taskParticipants },
          () => {
            this.props.handleStepper(this.state.filterData, 'participants')
          }
        )
      }
    } else {
      this.displayNotification(
        person[0].display_name,
        status,
        ' has already been added to this list as ADMIN'
      )
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }
  handleSearch = (event) => {
    const data = this.state.data
    let searchText = event.target.value
    let result = data.filter((item) =>
      Object.keys(item).some(
        (k) =>
          item[k] != null &&
          item[k].toString().toLowerCase().includes(searchText.toLowerCase())
      )
    )
    this.setState({ filterData: result, searchValue: event.target.value })
  }
  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  getSorting() {
    return this.state.order === 'desc'
      ? (a, b) => this.desc(a, b, this.state.orderBy)
      : (a, b) => -this.desc(a, b, this.state.orderBy)
  }

  handleRequestSort(property) {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }
  generateTr() {
    let rows = []
    const generatedList = this.state.filterData.filter(function (obj) {
      return obj.show === undefined || obj.show === true
    })
    const {
      classes = {},
      expandInfo = {},
      info = {},
      loading = {},
      onExpand,
    } = this.props
    const { editTaskInfo = {} } = info
    let taskId = editTaskInfo.job_id || ''

    generatedList.sort(this.getSorting()).forEach((user = {}, index) => {
      const {
        access_type = '',
        display_name = '',
        group_id,
        first_name = '',
        last_name = '',
        role = '',
        user_image = '',
        organization = '',
        email = '',
        email_address = '',
      } = user
      let rowExpand = !!(expandInfo[group_id] && expandInfo[group_id].expanded)

      rows.push(
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themeRow} key={index}>
            <TableRow className={group_id ? classes.groupRow : ''}>
              <TableCell />
              <TableCell
                component="th"
                scope="row"
                className={classes.rowImage}
              >
                {group_id ? (
                  <div className={classes.row}>
                    <Avatar className={classes.avatar}>
                      <IconButton aria-label="Access type" size="large">
                        <GroupIcon />
                      </IconButton>
                    </Avatar>
                    <span className={classes.userEmail}>
                      {group_id ? display_name : first_name + ' ' + last_name}
                    </span>
                  </div>
                ) : (
                  <div className={classes.row}>
                    <Fab className={classes.fab}>
                      {display_name
                        ? display_name.split('.').length > 1
                          ? display_name.split('.')[0].substring(0, 1) +
                            display_name.split('.')[1].substring(0, 1)
                          : display_name.split('.')[0].substring(0, 1)
                        : ''}
                    </Fab>
                    <div className={classes.participantIcon}></div>
                    {getDisplayName(
                      email_address ? email_address : email,
                      organization,
                      classes
                    )}
                  </div>
                )}
              </TableCell>
              <TableCell>
                <span>
                  <UserRoleIcon roleName={role} classes={classes} />
                </span>
              </TableCell>
              <TableCell>
                {role !== 'admin' && (
                  <IconButton
                    data-cy="deleteUserButton"
                    aria-label="Delete"
                    onClick={(event) => this.handler(event, user)}
                    size="large"
                  >
                    <DeleteIcon className={classes.linkIcon} />
                  </IconButton>
                )}
              </TableCell>
              <TableCell>
                {group_id ? (
                  loading.expandTaskGroupLoading[group_id] ? (
                    <CircularProgress />
                  ) : (
                    <IconButton
                      className={classes.button}
                      aria-label="Expand"
                      onClick={() => {
                        onExpand(group_id, rowExpand, taskId)
                      }}
                      size="large"
                    >
                      {rowExpand ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                    </IconButton>
                  )
                ) : (
                  ''
                )}
              </TableCell>
            </TableRow>
          </ThemeProvider>
        </StyledEngineProvider>
      )
      if (expandInfo[group_id]) {
        expandInfo[group_id].users &&
          expandInfo[group_id].users.map((groupUser, groupIndex) =>
            rows.push(
              <TableRow key={index + '/' + groupIndex}>
                <TableCell style={{ border: 0 }} />
                <TableCell className={classes.groupUsers}>
                  <Fab className={classes.fab}>
                    {groupUser.display_name
                      ? groupUser.display_name.split('.').length > 1
                        ? groupUser.display_name.split('.')[0].substring(0, 1) +
                          groupUser.display_name.split('.')[1].substring(0, 1)
                        : groupUser.display_name.split('.')[0].substring(0, 1)
                      : ''}
                  </Fab>
                  <div className={classes.participantIcon}></div>
                  <span className={classes.userDisplayName}>
                    {groupUser.display_name}
                  </span>
                </TableCell>
                <TableCell colSpan={3}>
                  <IconButton aria-label="Access type" size="large">
                    <GroupIcon />
                  </IconButton>
                  {access_type}
                </TableCell>
              </TableRow>
            )
          )
      }
    })
    return rows
  }

  render() {
    const { classes = {}, tmList, uploadTaskType } = this.props
    const { order, orderBy, seleactSearchValue, filterData } = this.state
    return (
      <Paper className={classes.tableWrapper}>
        <div>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            className={classes.cardHeader}
          >
            {uploadTaskType && (
              <Grid
                container
                alignItems="flex-end"
                style={{ marginTop: 10 }}
                item
                xs={12}
                md={6}
              >
                <Grid item>
                  <img
                    alt="Uploader"
                    className={classes.FormSVG}
                    src={UploaderIcon}
                  />
                </Grid>
                <Grid item sm style={{ marginLeft: 10 }}>
                  <AutocompleteChip
                    tmList={tmList}
                    onNameChange={(name) =>
                      this.userNameChange(name, 'uploader')
                    }
                    hideClose
                    fieldName="task_uploaders"
                    Label="Add an Uploader"
                    values={[]}
                    isChipRequired={false}
                    onChange={(name, value) => this.userChange(name, value)}
                    className={classes.AutoComplete}
                    onChipPasteCallBack={(arr) =>
                      this.userNameAfterPaste('task_uploaders', arr)
                    }
                  />
                </Grid>
              </Grid>
            )}
            {!uploadTaskType && (
              <Fragment>
                <Grid item xs={12} md={6} className={classes.leftSection}>
                  <Grid
                    container
                    alignItems="flex-end"
                    style={{ marginTop: 10 }}
                  >
                    <Grid item>
                      <img
                        alt="Reviewer"
                        className={classes.FormSVG}
                        src={ReviewerSvg}
                      />
                    </Grid>
                    <Grid
                      data-cy="reviewerInput"
                      item
                      sm
                      style={{ marginLeft: 10 }}
                    >
                      <AutocompleteChip
                        tmList={tmList}
                        onNameChange={(name) =>
                          this.userNameChange(name, 'reviewer')
                        }
                        hideClose
                        fieldName="task_reviewers"
                        Label="Enter Task Reviewers"
                        values={[]}
                        isChipRequired={false}
                        onChange={(name, value) => this.userChange(name, value)}
                        className={classes.AutoComplete}
                        onChipPasteCallBack={(arr) =>
                          this.userNameAfterPaste('task_reviewers', arr)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} className={classes.rightSection}>
                  <Grid container alignItems="flex-end">
                    <Grid item>
                      <FaceIcon className={classes.FormIcon} />
                    </Grid>
                    <Grid
                      data-cy="observerInput"
                      item
                      sm
                      style={{ marginLeft: 10 }}
                    >
                      <AutocompleteChip
                        tmList={tmList}
                        onNameChange={(name) =>
                          this.userNameChange(name, 'observer')
                        }
                        hideClose
                        fieldName="task_observers"
                        Label="Enter Task Observers"
                        values={[]}
                        isChipRequired={false}
                        onChange={(name, value) => this.userChange(name, value)}
                        className={classes.AutoComplete}
                        onChipPasteCallBack={(arr) =>
                          this.userNameAfterPaste('task_observers', arr)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
            )}
          </Grid>
        </div>
        <div>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={6} className={classes.countUser}>
              {this.state.data.length} people are assigned to this task.
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="baseline">
            <Grid item>
              <EnhancedTableFilterbarMok
                numSelected={filterData.length}
                handleSearch={this.handleSearch}
                value={this.searchValue}
                selectValue={seleactSearchValue}
              />
            </Grid>
          </Grid>
        </div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 1 }} />
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'display_name'}
                  direction={order}
                  onClick={() => this.handleRequestSort('display_name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'access_type'}
                  direction={order}
                  onClick={() => this.handleRequestSort('access_type')}
                >
                  Role
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.actionRow}>Actions</TableCell>
              <TableCell className={classes.actionRow}>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.generateTr()}</TableBody>
        </Table>
      </Paper>
    )
  }
}

TaskPeopleList.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSearch: PropTypes.func,
  info: PropTypes.shape({
    editTaskInfo: PropTypes.shape({
      job_id: PropTypes.string,
    }),
    task: PropTypes.shape({
      current: PropTypes.shape({
        participants: PropTypes.arrayOf({
          addedTime: PropTypes.instanceOf(Date),
        }),
      }),
      default: PropTypes.shape({
        participants: PropTypes.arrayOf({
          addedTime: PropTypes.instanceOf(Date),
        }),
      }),
    }),
  }),
  value: PropTypes.string,
  handleStepper: PropTypes.func,
  getTmList: PropTypes.func,
  showNotification: PropTypes.func,
  expandInfo: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.shape({
    expandTaskGroupLoading: PropTypes.arrayOf(PropTypes.string),
  }),
  onExpand: PropTypes.func,
  tmList: PropTypes.arrayOf(PropTypes.object),
  uploadTaskType: PropTypes.bool,
}

const mapStateToProps = (state) => {
  const { tmList } = state
  return {
    tmList,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTmList,
      showNotification,
      fetchTeamMemberList,
    },
    dispatch
  )

const MyComponent = (props) => {
  const classes = styles()
  return <TaskPeopleList {...props} classes={classes} />
}

export default connect(mapStateToProps, mapDispatchToProps)(MyComponent)
