import React, { useEffect, useRef, useReducer, startTransition } from 'react'
import { makeStyles } from '@mui/styles'
import { isEmpty, isEqual } from 'lodash'
import Grid from '@mui/material/Grid'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import {
  fetchReviewTemplates,
  saveSelectedItems,
  searchAssetsList,
  searchRSAssetsList,
  saveSearchByTextPayload,
  saveSearchPersistState,
  resetFilters,
  enableTriggerSearchCall,
  updateSortFields,
  updateRowsPerPage,
  updatePageNumber,
  refreshSearch,
  updateSearchTerm,
  updateUserSavedFilters,
  updateSearchTermWithType,
} from '../../store/search/searchSlice'
import {
  DEFAULT_PAGE,
  DEFAULT_ROWSPERPAGE,
  SEARCH_PAGE_SIZES,
  SORT_OPTIONS,
  SORT_BY_OPTIONS,
  DEFAULT_SORT_BY_OPTION,
  searchDefaultPayload,
  DEFAULT_SEARCH_RENDER_PAGE,
  DEFAULT_SEARCH_TYPE,
} from '../../constants/search'
import CircularPageLoader from '../../components/Loader/CircularPageLoader'
import {
  fetchUserPreference,
  updateUserPreference,
} from '../../store/preference/userPreferenceSlice'

import SearchContext from './SearchContext'
import FiltersLayout from './Filters'
import SearchByImageLayout from './SearchByImage/SearchByImageLayout'
import SearchPageComponents from './'
import {
  BetaAnalytics,
  trackBetaSearchAnalytics,
} from '../../../analytics/betaSearch'

const styles = makeStyles(
  (theme) => ({
    searchContainer: {
      padding: '20px 20px 50px 20px',
    },
    filtersLayout: {
      background: '#b8a7a714',
    },
    paginationLayout: {
      background: '#b8a7a714',
      paddingTop: '10px !important',
    },
    pageContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '0px 0px 10px 15px',
      justifyContent: 'space-between',
    },
    selectSection: {
      justifyContent: 'flex-start',
      width: '48%',
      gap: '15px',
      alignItems: 'center',
    },
    searchAssets: {
      maxHeight: '70vh',
      overflow: 'scroll',
    },
    selectAllLabel: {
      padding: '2px 11px 2px 2px',
      borderRadius: '8px',
      border: '1px solid rgb(136, 136, 136)',
      height: '2.5rem',
      margin: 1,
    },
    selectAll: {
      fontSize: '0.875rem',
    },
  }),
  { index: 1 },
)

function SearchPage() {
  const classes = styles()
  const actionDispatch = useDispatch()
  const navigate = useNavigate()
  const pageSizes = [...SEARCH_PAGE_SIZES, 500, 750, 1000]
  const {
    reducerState = {},
    assetList = [],
    totalResults = Number(0),
    filters = {},
    facets = {},
    channelFacets = {},
    selectedItems: curSelectedItems = {},
    searchTerm = '',
    searchType = DEFAULT_SEARCH_TYPE,
    strictSearch = false,
    triggerSearchCall,
    colors = [],
    userPreferences = {},
    templateId = '',
    searchPayload = {},
    searchPersistState = {},
    pageLoading,
    searchPreference = {},
    reducerPage = Number(0),
    reducerRowsPerPage = Number(21),
    reducerSortFields = SORT_BY_OPTIONS[DEFAULT_SORT_BY_OPTION],
  } = useSelector((state) => ({
    reducerState: state,
    assetList: state?.newSearch?.assetList || [],
    totalResults: state?.newSearch?.totalResults || Number(0),
    filters: state?.newSearch?.filters || {},
    facets: state?.newSearch?.facets || {},
    channelFacets: state?.newSearch?.channelFacets || {},
    selectedItems: state?.newSearch?.selectedItems || {},
    searchTerm: state?.newSearch?.searchTerm || '',
    searchType: state?.newSearch?.searchType || '',
    strictSearch: state?.newSearch?.strictSearch || false,
    triggerSearchCall: state?.newSearch?.triggerSearchCall || false,
    colors: state?.newUserPreference?.colors || [],
    searchPreference: state?.newUserPreference?.searchPreference || {},
    userPreferences: state?.newUserPreference?.userPreferences || {},
    templateId: state?.newSearch?.templateId || '',
    searchPayload: state?.newSearch?.searchPayload || {},
    searchPersistState: state?.newSearch?.searchState || {},
    pageLoading: state?.newSearch?.pageLoading,
    reducerPage: state?.newSearch?.page,
    reducerRowsPerPage: state?.newSearch?.rowsPerPage,
    reducerSortFields: state?.newSearch?.sortFields,
  }))
  const reducerStateRef = useRef(reducerState)
  reducerStateRef.current = reducerState

  const initialState = {
    page: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_ROWSPERPAGE,
    sortFields: SORT_BY_OPTIONS[DEFAULT_SORT_BY_OPTION],
    sortBy: DEFAULT_SORT_BY_OPTION,
    apiPayload: searchDefaultPayload,
    searchByImagePayload: {},
    selectedItems: [],
    selectAll: false,
    selectAllClicked: false,
    isAssetChecked: false,
    openImageSearchDialog: false,
    uploadedImageRS: null,
    searchByImageTriggered: false,
    renderPage: DEFAULT_SEARCH_RENDER_PAGE,
    previewImageRS: null,
  }

  const { search: searchQuery = '' } = useLocation()
  const parsedStringFromUrl = queryString.parse(searchQuery) || {}
  let searchTermFromUrl = ''
  let searchTypeFromUrl = ''
  let strictSearchFromUrl = false
  if (parsedStringFromUrl) {
    const { term = '', type = '', strict = '' } = parsedStringFromUrl
    searchTermFromUrl = term
    searchTypeFromUrl = type
    strictSearchFromUrl = strict === 'true'? true: false
  }

  const reducer = (state, action) => {
    const { payload: actionPayload, type } = action
    switch (type) {
      case 'AHSEARCH_SET_SEARCHBYIMAGE_APIPAYLOAD':
        return { ...state, searchByImagePayload: actionPayload }
      case 'AHSEARCH_ON_SELECTION_CLEAR':
      case 'AHSEARCH_ON_SELECTALL_CLICK':
      case 'AHSEARCH_ON_ASSET_SELECT':
      case 'AHSEARCH_OPEN_SEARCHBYIMAGE_DIALOAG':
      case 'AHSEARCH_SEARCHBYIMAGE_TRIGGERED':
      case 'AHSEARCH_BACKTOSEARCH_TRIGGERED':
      case 'AHSEARCH_RETAIN_STATE':
        return { ...state, ...actionPayload }
      case 'AHSEARCH_SET_RENDERPAGE':
        return { ...state, renderPage: actionPayload }
      case 'AHSEARCH_REFRESH_SEARCH':
        return { ...initialState }
      default:
        return state
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const selectAllCheck = () => {
    const filteredData =
      assetList.filter((asset) => {
        const { assetId = '' } = asset
        if (
          Object.keys(state?.selectedItems).length &&
          state?.selectedItems.some((obj) => obj.assetId === assetId)
        ) {
          return asset
        }
        return null
      }) || []
    if (filteredData.length === assetList.length) {
      return true
    }
    return false
  }

  const getFinalPayload = () => {
    const stateApiPayload =
      Object.entries(searchPayload)?.length > 0 ? searchPayload : {}
    let finalPayload = { ...stateApiPayload }
    finalPayload = templateId
      ? {
          ...stateApiPayload,
          template_id: templateId,
          filters: {},
          search_term: '',
          search_type: searchType,
          strict: strictSearch,
          sort_fields: reducerSortFields,
        }
      : {
          ...stateApiPayload,
          template_id: '',
          filters: filters,
          search_term: searchTerm,
          search_type: searchType,
          strict: strictSearch,
          sort_fields: reducerSortFields,
        }
    return finalPayload
  }

  useEffect(() => {
    // Call to Search
    if (triggerSearchCall) {
      const searchPayload = getFinalPayload()
      actionDispatch(
        searchAssetsList({
          payload: searchPayload,
          page: reducerPage,
          rowsPerPage: reducerRowsPerPage,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSearchCall])

  useEffect(() => {
    // Call to Search
    if (searchTermFromUrl && searchTermFromUrl !== searchTerm) {
      actionDispatch(
        updateSearchTermWithType({
          searchTerm: searchTermFromUrl,
          searchType: searchTypeFromUrl,
          strictSearch: strictSearchFromUrl,
        }),
      )
      navigate(
        searchTermFromUrl
          ? `?type=${searchTypeFromUrl}&term=${searchTermFromUrl}&strict=${strictSearchFromUrl}`
          : '',
      )
    } else {
      actionDispatch(enableTriggerSearchCall())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTermFromUrl])

  useEffect(() => {
    if (assetList?.length) {
      // setSelectAll(selectAllCheck())
      dispatch({
        type: 'AHSEARCH_ON_SELECTALL_CLICK',
        payload: {
          selectAll: selectAllCheck(),
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetList])

  useEffect(() => {
    actionDispatch(fetchUserPreference())
    actionDispatch(fetchReviewTemplates())
    return () => {
      saveSearchPreference()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const currentPage = state?.page || Number(0)
    const stateRowsPerPage = state?.rowsPerPage || Number(10)
    const stateApiPayload = state?.searchByImagePayload || {}
    if (state?.searchByImagePayload?.file) {
      actionDispatch(resetFilters)
      actionDispatch(
        searchRSAssetsList({
          payload: stateApiPayload,
          page: currentPage,
          rowsPerPage: stateRowsPerPage,
        }),
      )
      trackAnalyticsData({
        eventType: BetaAnalytics.event.ADD_FILTER,
        eventName: BetaAnalytics.event.REVERSE_IMAGE_SEARCH,
        eventData: {
          fileName: stateApiPayload.file.name,
          fileSize: stateApiPayload.file.size,
          fileType: stateApiPayload.file.type,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.searchByImagePayload?.file])

  useEffect(() => {
    if (state?.selectedItems?.length > 0 && state?.isAssetChecked) {
      dispatch({
        type: 'AHSEARCH_ON_SELECTALL_CLICK',
        payload: {
          selectAll: selectAllCheck(),
        },
      })
    }
    return function cleanup() {
      dispatch({
        type: 'AHSEARCH_ON_ASSET_SELECT',
        payload: {
          isAssetChecked: false,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.selectedItems])

  useEffect(() => {
    if (!curSelectedItems.length) {
      dispatch({
        type: 'AHSEARCH_ON_SELECTION_CLEAR',
        payload: {
          selectAll: !state?.selectAll,
          isAssetChecked: false,
          selectedItems: [],
          selectAllClicked: false,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curSelectedItems.length])

  useEffect(() => {
    if (state?.searchByImageTriggered && state?.uploadedImageRS) {
      dispatch({
        type: 'AHSEARCH_SET_SEARCHBYIMAGE_APIPAYLOAD',
        payload: {
          file: state?.uploadedImageRS,
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.searchByImageTriggered, state?.uploadedImageRS])

  useEffect(() => {
    let result = []
    const stateSelectedItems = state?.selectedItems
    if (state?.selectAllClicked) {
      if (state?.selectAll) {
        result = [...stateSelectedItems, ...assetList]
      } else {
        // compare array objects and get differences
        result = stateSelectedItems.filter(
          (o1) => !assetList.some((o2) => o1.assetId === o2.assetId),
        )
      }

      //array of object - unique values
      const finalSelectedItems = result.filter(
        (obj, index) =>
          index === result.findIndex((o) => obj.assetId === o.assetId),
      )
      dispatch({
        type: 'AHSEARCH_ON_SELECTALL_CLICK',
        payload: {
          selectedItems: finalSelectedItems,
        },
      })
      actionDispatch(saveSelectedItems({ selectedItems: finalSelectedItems }))
    }
    return function cleanup() {
      dispatch({
        type: 'AHSEARCH_ON_SELECTALL_CLICK',
        payload: {
          selectAllClicked: false,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.selectAllClicked])

  const onSelection = (event, curAsset = {}) => {
    const {
      assetId = '',
      originalAssetId = '',
      fileName = '',
      fileSizeMb = '',
      renderUrls = {},
      uploadedDate = null,
      originalUploadedDate = null,
      isDerivative,
      projectInfo = {},
      itemInfo = [],
      usageInfo = [],
      assetClassification = {},
    } = curAsset
    let newDate = ''
    if (originalUploadedDate) {
      const date = Date.parse(originalUploadedDate)
      newDate = new Date(date).toISOString()
    }

    const { downloadUrl = '' } = renderUrls
    const curAssetObj = {
      assetId: assetId,
      assetMasterId: originalAssetId,
      fileName: fileName,
      filePath: downloadUrl,
      fileSizeMb: fileSizeMb,
      importDate: newDate, //In ISO format to use it as an api param
      originalUploadedDate: originalUploadedDate, //As it is from the api
      uploadedDate: uploadedDate, //Date in string format
      render_urls: renderUrls,
      isDerivative,
      itemInfo,
      usageInfo,
      projectInfo,
      assetClassification,
    }

    const stateSelectedItems = state?.selectedItems || []

    let curSelectedList = []
    const selectedTargetValue =
      typeof event === 'object' ? event.target.checked : event
    if (selectedTargetValue) {
      curSelectedList = [...stateSelectedItems, ...[curAssetObj]]
    } else {
      curSelectedList = stateSelectedItems?.filter(
        (item) => item?.assetId !== assetId,
      )
    }
    dispatch({
      type: 'AHSEARCH_ON_ASSET_SELECT',
      payload: {
        selectedItems: curSelectedList,
        isAssetChecked: true,
      },
    })
    actionDispatch(saveSelectedItems({ selectedItems: curSelectedList }))
  }

  const handlePageChange = (page = DEFAULT_PAGE) => {
    dispatch({
      type: 'AHSEARCH_SET_PAGE',
      payload: page,
    })
    actionDispatch(updatePageNumber(page))
    trackAnalyticsData({
      eventName: BetaAnalytics.event.PAGE_CHANGE,
      eventType: BetaAnalytics.event.PAGE_CHANGE,
      eventData: {
        key: 'page',
        value: page,
      },
    })
  }

  const handleRowsPerPageChange = (rowsPerPage = DEFAULT_ROWSPERPAGE) => {
    dispatch({
      type: 'AHSEARCH_SET_ROWSPERPAGE',
      payload: rowsPerPage,
    })
    actionDispatch(updateRowsPerPage(rowsPerPage))
    trackAnalyticsData({
      eventName: BetaAnalytics.event.ROWS_PER_PAGE_CHANGE,
      eventType: BetaAnalytics.event.ROWS_PER_PAGE_CHANGE,
      eventData: {
        key: 'rowsPerPage',
        value: rowsPerPage,
      },
    })
  }

  const handleSortChange = (selSortBy = '') => {
    const selSortFields = SORT_BY_OPTIONS[selSortBy]
    actionDispatch(updateSortFields(selSortFields))
    dispatch({
      type: 'AHSEARCH_ON_SORT',
      payload: {
        sortBy: selSortBy,
        sortFields: selSortFields,
      },
    })
    trackAnalyticsData({
      eventName: BetaAnalytics.event.SORT_PREFERENCE,
      eventType: BetaAnalytics.event.SORT_PREFERENCE,
      eventData: {
        key: 'sortBy',
        value: selSortBy,
      },
    })
  }

  const handleSelectAll = () => {
    dispatch({
      type: 'AHSEARCH_ON_SELECTALL_CLICK',
      payload: {
        selectAll: !state?.selectAll,
        selectAllClicked: true,
      },
    })
  }

  const setImageSearchDialogOpen = (isImageSearch = false) => {
    dispatch({
      type: 'AHSEARCH_OPEN_SEARCHBYIMAGE_DIALOAG',
      payload: {
        openImageSearchDialog: isImageSearch,
      },
    })
  }

  const triggerSearchImage = async (file, openImageSearchDialog = false) => {
    const preview = URL.createObjectURL(file)
    const stateApiPayload = getFinalPayload() || searchPayload || {}
    await dispatch({
      type: 'AHSEARCH_SEARCHBYIMAGE_TRIGGERED',
      payload: {
        uploadedImageRS: file,
        previewImageRS: preview,
        openImageSearchDialog: openImageSearchDialog,
        searchByImageTriggered: true,
        renderPage: 'SEARCHBYIMAGE',
        apiPayload: {}, //commenting to avoid search api call
      },
    })
    await actionDispatch(
      saveSearchByTextPayload({ searchPayload: stateApiPayload }),
    )
  }

  const triggerBackToSearch = () => {
    dispatch({
      type: 'AHSEARCH_BACKTOSEARCH_TRIGGERED',
      payload: {
        uploadedImageRS: null,
        previewImageRS: null,
        openImageSearchDialog: false,
        searchByImageTriggered: false,
        renderPage: DEFAULT_SEARCH_RENDER_PAGE,
        apiPayload: searchPayload,
        file: null,
      },
    })
    actionDispatch(enableTriggerSearchCall())
  }

  const triggerRefreshSearch = () => {
    actionDispatch(refreshSearch())
    navigate('')
    trackAnalyticsData({
      eventName: BetaAnalytics.event.REFRESH_SEARCH,
      eventType: BetaAnalytics.event.REFRESH_SEARCH,
      eventData: {
        filters,
        searchTerm,
        searchType,
        strictSearch,
        templateId,
        reducerPage,
        reducerRowsPerPage,
        reducerSortFields,
        curSelectedItems,
      },
    })
  }

  const constructSearchPreferencePayload = () => {
    const existingState = reducerStateRef.current
    const commonSearchPayload = {
      ...searchDefaultPayload,
      page: existingState.newSearch.page,
      rowsPerPage: existingState.newSearch.rowsPerPage,
      sort_fields: existingState.newSearch.sortFields,
    }
    let preferencePayload = existingState.newSearch.templateId
      ? {
          ...commonSearchPayload,
          template_id: existingState.newSearch.templateId,
          filters: {},
          search_term: '',
        }
      : {
          ...commonSearchPayload,
          template_id: '',
          filters: existingState.newSearch.filters,
          search_term: existingState.newSearch.searchTerm,
        }
    return preferencePayload
  }

  const saveSearchPreference = () => {
    const searchPreferencePayload = constructSearchPreferencePayload()
    actionDispatch(
      updateUserPreference({
        ...userPreferences,
        preferences: {
          ...userPreferences.preferences,
          search_preference: searchPreferencePayload,
        },
      }),
    )
  }

  const saveSearchPayload = () => {}

  const setRenderPage = (renderPage = DEFAULT_SEARCH_RENDER_PAGE) => {
    dispatch({
      type: 'AHSEARCH_SET_RENDERPAGE',
      payload: renderPage,
    })
  }

  const renderLayoutHeader = () => {
    const currentRenderPage = state?.renderPage
    switch (currentRenderPage) {
      case 'SEARCHBYIMAGE':
        return <SearchByImageLayout />
      default:
        return <FiltersLayout />
    }
  }

  const trackAnalyticsData = (trackingData = {}) => {
    const { auth = {} } = reducerState
    trackBetaSearchAnalytics({ auth, trackingData })
  }

  return (
    <SearchContext.Provider
      value={{
        assetList: assetList,
        onSelection: onSelection,
        selectedItems: state?.selectedItems,
        selectAll: state?.selectAll,
        page: state?.page,
        rowsPerPage: state?.rowsPerPage,
        totalResults: totalResults,
        filters: filters,
        facets: facets,
        channelFacets: channelFacets,
        searchTerm: searchTerm,
        triggerSearchCall: triggerSearchCall,
        userPreferences: userPreferences,
        colors: colors,
        setImageSearchDialogOpen: setImageSearchDialogOpen,
        openImageSearchDialog: state?.openImageSearchDialog,
        triggerSearchImage: triggerSearchImage,
        searchByImageTriggered: state?.searchByImageTriggered,
        previewImageRS: state?.previewImageRS,
        triggerBackToSearch: triggerBackToSearch,
        triggerRefreshSearch: triggerRefreshSearch,
        saveSearchPayload: saveSearchPayload,
        setRenderPage: setRenderPage,
        renderPage: state?.renderPage,
        trackAnalyticsData: trackAnalyticsData,
      }}
    >
      <Grid container spacing={4} className={classes.searchContainer}>
        <Grid item xs={12} md={12} lg={12} className={classes.filtersLayout}>
          {renderLayoutHeader()}
        </Grid>
        <Grid item xs={12} md={12} lg={12} className={classes.paginationLayout}>
          <Grid container className={classes.pageContainer}>
            {totalResults > Number(0) && (
              <Grid item container className={classes.selectSection}>
                <Grid item>{totalResults} Results</Grid>
                <Grid item>
                  <FormControlLabel
                    value="selectall"
                    control={
                      <Checkbox
                        checked={selectAllCheck()}
                        onChange={handleSelectAll}
                      />
                    }
                    className={classes.selectAllLabel}
                    label={
                      <span className={classes.selectAll}>
                        {'Select all on page'}
                      </span>
                    }
                    data-cy={'searchSelectAll'}
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            )}
            {!state?.searchByImageTriggered && (
              <Grid item>
                <Grid container alignItems={'flex-end'}>
                  <Grid item>
                    <SearchPageComponents.SortOptions
                      dropDownName={'Sort By: '}
                      dropDownLabel={'search'}
                      dropDownValue={state?.sortBy}
                      dropDownOptions={SORT_OPTIONS}
                      onDropdownChange={handleSortChange}
                    />
                  </Grid>

                  <Grid item>
                    <SearchPageComponents.Pagination
                      page={reducerPage}
                      rowsPerPage={reducerRowsPerPage}
                      totalCount={totalResults}
                      rowsPerPageOptions={pageSizes}
                      handlePageChange={handlePageChange}
                      handleRowsPerPageChange={handleRowsPerPageChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12} className={classes.searchAssets}>
          {pageLoading ? (
            <CircularPageLoader open={true} />
          ) : (
            <SearchPageComponents.AssetList />
          )}
        </Grid>
      </Grid>
    </SearchContext.Provider>
  )
}
export default React.memo(SearchPage)
