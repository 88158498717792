import { Chip, FormControl, Input, InputLabel } from '@mui/material'

import CollapsibleCard from '../../components/Custom/CollapsibleCard'
import {
  METADATA_CARD_HIDE_BUTTON_TEXT,
  METADATA_CARD_SHOW_BUTTON_TEXT,
} from '../../constants/metadata'
import { useMetadataContext } from './MetadataContext'

function AIMetadataCard({ containerClasses = {} }) {
  const { assetMetadata = {} } = useMetadataContext()
  const { metadata: { ai = {} } = {} } = assetMetadata
  const { asset_keywords = {}, asset_classification = {}, asset_description = {}, asset_talent = '' } = ai || {}
  const { keywords = [] } = asset_keywords || {}
  const { description = '' } = asset_description || {}
  const { classification = '' } = asset_classification || {}
  const { is_talent = false } = asset_talent || {}

  const renderNoDetailsAvailable = () => (
    <div className={containerClasses.noResults}>
      No details available
    </div>
  )

  return (
    <div className={containerClasses.aiMetadataGrid}>
      <CollapsibleCard
        cardTitle={'AI Keywords'}
        hideButtonText={METADATA_CARD_HIDE_BUTTON_TEXT}
        showButtonText={METADATA_CARD_SHOW_BUTTON_TEXT}
        defaultCollapse={true}
        containerClasses={containerClasses}
      >
        {keywords?.length ? (
          keywords.map((keyword) => <Chip label={keyword} className={containerClasses.aiMetadataChipClass}/>)
        ) : renderNoDetailsAvailable()
        }
      </CollapsibleCard>
      <CollapsibleCard
        cardTitle={'AI Classification'}
        hideButtonText={METADATA_CARD_HIDE_BUTTON_TEXT}
        showButtonText={METADATA_CARD_SHOW_BUTTON_TEXT}
        defaultCollapse={true}
        containerClasses={containerClasses}
      >
        {classification ? <Chip label={`Product ${classification} ${is_talent? 'With': 'Without'} Talent`} className={containerClasses.aiMetadataChipClass}/> : renderNoDetailsAvailable()}
      </CollapsibleCard>

      <CollapsibleCard
        cardTitle={'AI Description'}
        hideButtonText={METADATA_CARD_HIDE_BUTTON_TEXT}
        showButtonText={METADATA_CARD_SHOW_BUTTON_TEXT}
        defaultCollapse={true}
        containerClasses={containerClasses}
      >
        {description ? <div className={containerClasses.aiMetadataChipClass}>{description}</div> : renderNoDetailsAvailable()}
      </CollapsibleCard>
    </div>
  )
}

export default AIMetadataCard
